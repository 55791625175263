import React from "react";

const ApplicationForm = ({
	formData,
	handleInputChange,
	handleSave,
	isEditing,
	setIsEditing,
	isSaving,
	hasUnsavedChanges,
	setFormData,
	detailedEntry,
	setHasUnsavedChanges,
	FIELD_MAPPINGS,
	formatDate,
}) => {
	const renderField = (label, name, type = "text", required = false) => (
		<div>
			<label className="block text-sm font-medium text-gray-700">{label}</label>
			<input
				type={type}
				name={name}
				value={formData[FIELD_MAPPINGS[name]] || ""}
				onChange={handleInputChange}
				className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
				required={required}
				disabled={!isEditing}
			/>
		</div>
	);

	return (
		<form onSubmit={handleSave} className="space-y-6">
			{/* Personal Information */}
			<div className="bg-gray-50 p-4 rounded-lg">
				<h3 className="text-lg font-medium mb-4">Personal Information</h3>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
					<div className="grid grid-cols-2 gap-4">
						{renderField("First Name", "firstName", "text", true)}
						{renderField("Last Name", "lastName", "text", true)}
					</div>
					{renderField("Email", "email", "email", true)}
					{renderField("Phone", "phone", "tel", true)}
				</div>
			</div>

			{/* Location Information */}
			<div className="bg-gray-50 p-4 rounded-lg">
				<h3 className="text-lg font-medium mb-4">Location</h3>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
					{renderField("City", "city", "text", true)}
					{renderField("Province", "province", "text", true)}
				</div>
			</div>

			{/* Skills & Interests */}
			<div className="bg-gray-50 p-4 rounded-lg">
				<h3 className="text-lg font-medium mb-4">Skills & Interests</h3>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
					<div className="space-y-4">
						<h4 className="font-medium">Construction</h4>
						{renderField("Construction Trade", "constructionTrade")}
						{renderField("Safety Courses", "safetyCourses")}
					</div>
					<div className="space-y-4">
						<h4 className="font-medium">Hospitality</h4>
						{renderField("Culinary", "culinary")}
						{renderField("Food Handling", "foodHandling")}
					</div>
				</div>
			</div>

			{/* Additional Information */}
			<div className="bg-gray-50 p-4 rounded-lg">
				<h3 className="text-lg font-medium mb-4">Additional Information</h3>
				<div className="space-y-4">
					<div>
						<label className="block text-sm font-medium text-gray-700">
							Other Interests
						</label>
						<textarea
							name="otherInterests"
							value={formData[FIELD_MAPPINGS.otherInterests] || ""}
							onChange={handleInputChange}
							rows={3}
							className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
							disabled={!isEditing}
						/>
					</div>
					{renderField("Volunteer Interest", "volunteer")}
				</div>
			</div>

			{/* Form Actions */}
			<div className="flex justify-end space-x-3 pt-4 border-t">
				{isEditing ? (
					<>
						<button
							type="button"
							onClick={() => {
								setFormData(detailedEntry);
								setIsEditing(false);
								setHasUnsavedChanges(false);
							}}
							className="px-4 py-2 text-gray-700 bg-gray-100 rounded hover:bg-gray-200"
							disabled={isSaving}
						>
							Cancel
						</button>
						<button
							type="submit"
							className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
							disabled={isSaving || !hasUnsavedChanges}
						>
							{isSaving ? "Saving..." : "Save Changes"}
						</button>
					</>
				) : (
					<button
						type="button"
						onClick={() => setIsEditing(true)}
						className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
					>
						Edit
					</button>
				)}
			</div>
		</form>
	);
};

export default ApplicationForm;
