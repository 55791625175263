import React from "react";

const ApplicationsTable = ({
	sortedEntries,
	handleSort,
	handleOpenModal,
	FIELD_MAPPINGS,
	formatDate,
	page,
	totalPages,
	setPage,
	entries,
}) => {
	return (
		<div className="space-y-4">
			<div className="bg-white rounded-lg shadow overflow-hidden">
				<div className="overflow-x-auto">
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								<th
									onClick={() => handleSort(FIELD_MAPPINGS.dateCreated)}
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
								>
									Date Created
								</th>
								<th
									onClick={() => handleSort(FIELD_MAPPINGS.firstName)}
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
								>
									Applicant
								</th>
								<th
									onClick={() => handleSort(FIELD_MAPPINGS.email)}
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
								>
									Contact
								</th>
								<th
									onClick={() => handleSort(FIELD_MAPPINGS.city)}
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
								>
									Location
								</th>
								<th
									onClick={() => handleSort(FIELD_MAPPINGS.constructionTrade)}
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
								>
									Skills
								</th>
								<th
									onClick={() => handleSort(FIELD_MAPPINGS.status)}
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
								>
									Status
								</th>
								<th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
									Actions
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{sortedEntries.map((entry) => (
								<tr key={entry.id} className="hover:bg-gray-50">
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											{
												formatDate(entry[FIELD_MAPPINGS.dateCreated]).split(
													",",
												)[0]
											}
										</div>
										<div className="text-sm text-gray-500">
											{
												formatDate(entry[FIELD_MAPPINGS.dateCreated]).split(
													",",
												)[1]
											}
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="flex items-center">
											<div className="ml-4">
												<div className="text-sm font-medium text-gray-900">
													{entry[FIELD_MAPPINGS.firstName]}{" "}
													{entry[FIELD_MAPPINGS.lastName]}
												</div>
												{entry[FIELD_MAPPINGS.volunteer] === "Yes" && (
													<span className="px-2 py-1 text-xs bg-green-100 text-green-800 rounded-full">
														Volunteer
													</span>
												)}
											</div>
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											{entry[FIELD_MAPPINGS.email]}
										</div>
										<div className="text-sm text-gray-500">
											{entry[FIELD_MAPPINGS.phone]}
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											{entry[FIELD_MAPPINGS.city]},{" "}
											{entry[FIELD_MAPPINGS.province]}
										</div>
									</td>
									<td className="px-6 py-4">
										<div className="flex flex-wrap gap-1">
											{entry[FIELD_MAPPINGS.constructionTrade] && (
												<span className="px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded-full">
													{entry[FIELD_MAPPINGS.constructionTrade]}
												</span>
											)}
											{entry[FIELD_MAPPINGS.safetyCourses] && (
												<span className="px-2 py-1 text-xs bg-green-100 text-green-800 rounded-full">
													{entry[FIELD_MAPPINGS.safetyCourses]}
												</span>
											)}
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<span
											className={`px-2 py-1 text-xs rounded-full ${
												entry.status === "active"
													? "bg-green-100 text-green-800"
													: "bg-gray-100 text-gray-800"
											}`}
										>
											{entry.status}
										</span>
									</td>
									<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
										<button
											onClick={() => handleOpenModal(entry)}
											className="text-blue-600 hover:text-blue-900"
										>
											Edit
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{/* Pagination */}
			<div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
				<div className="flex justify-between items-center w-full">
					<button
						onClick={() => setPage((p) => Math.max(1, p - 1))}
						disabled={page === 1}
						className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
					>
						Previous
					</button>
					<div className="flex items-center gap-2">
						<span className="text-sm text-gray-700">
							Page {page} of {totalPages}
						</span>
						<span className="text-sm text-gray-500">
							({entries.length} entries)
						</span>
					</div>
					<button
						onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
						disabled={page === totalPages}
						className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default ApplicationsTable;
