import React, { useState, useEffect } from "react";
import EnrollmentDialog from "../../components/atsflow/EnrollmentDialog";
import EnrollmentsTable from "../../components/atsflow/EnrollmentsTable";

const SCORE_TYPES = [
	{ value: "Incomplete", label: "Incomplete" },
	{ value: "Pass", label: "Pass" },
	{ value: "Fail", label: "Fail" },
];

const Enrollments = () => {
	const [enrollments, setEnrollments] = useState([]);
	const [applicants, setApplicants] = useState([]);
	const [courses, setCourses] = useState([]);
	const [institutions, setInstitutions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentEnrollment, setCurrentEnrollment] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [alert, setAlert] = useState({ type: "", message: "" });
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const [enrollmentsRes, applicantsRes, coursesRes, institutionsRes] =
				await Promise.all([
					fetch("/ats-enrollments/"),
					fetch("/ats-applicants/"),
					fetch("/ats-courses/"),
					fetch("/ats-institutions/"),
				]);

			if (!enrollmentsRes.ok) throw new Error("Failed to fetch enrollments");
			if (!applicantsRes.ok) throw new Error("Failed to fetch applicants");
			if (!coursesRes.ok) throw new Error("Failed to fetch courses");
			if (!institutionsRes.ok) throw new Error("Failed to fetch institutions");

			const [enrollmentsData, applicantsData, coursesData, institutionsData] =
				await Promise.all([
					enrollmentsRes.json(),
					applicantsRes.json(),
					coursesRes.json(),
					institutionsRes.json(), // Fixed typo: was coursesData.json()
				]);

			setEnrollments(enrollmentsData);
			setApplicants(applicantsData);
			setCourses(coursesData);
			setInstitutions(institutionsData);
		} catch (error) {
			console.error("Fetch error:", error); // Added error logging
			setAlert({
				type: "error",
				message: "Failed to load data. Please try again.",
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const validateEnrollmentData = (data) => {
		const errors = [];
		const required = ["applicant_id", "course_id", "enrollment_date"];

		required.forEach((field) => {
			if (!data[field]) {
				errors.push(`${field.replace(/_/g, " ")} is required`);
			}
		});

		// Validate enrollment date is not in past
		if (data.enrollment_date) {
			const enrollmentDate = new Date(data.enrollment_date);
			if (enrollmentDate < new Date()) {
				errors.push("Enrollment date cannot be in the past");
			}
		}

		return errors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const enrollmentData = Object.fromEntries(formData.entries());

		const errors = validateEnrollmentData(enrollmentData);
		if (errors.length > 0) {
			setAlert({
				type: "error",
				message: `Validation errors: ${errors.join(", ")}`,
			});
			return;
		}

		try {
			const response = await fetch(
				`/ats-enrollments/${currentEnrollment?.id || ""}`,
				{
					method: isEditing ? "PUT" : "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(enrollmentData),
				},
			);

			if (!response.ok) throw new Error("Failed to save enrollment");

			setAlert({
				type: "success",
				message: `Enrollment successfully ${isEditing ? "updated" : "created"}`,
			});
			setIsModalOpen(false);
			fetchData();
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to save enrollment. Please try again.",
			});
		}
	};

	const getEnrollmentDetails = (enrollment) => {
		const applicant = applicants.find((a) => a.id === enrollment.applicant_id);
		const course = courses.find((c) => c.id === enrollment.course_id);
		const institution = course
			? institutions.find((i) => i.id === course.institution_id)
			: null;

		return {
			...enrollment,
			applicant,
			course,
			institution,
		};
	};

	const getScoreBadgeClass = (score) => {
		switch (score) {
			case "Pass":
				return "bg-green-100 text-green-800";
			case "Fail":
				return "bg-red-100 text-red-800";
			default:
				return "bg-yellow-100 text-yellow-800";
		}
	};

	// Format courses for select component
	const formattedCourses = courses.map((course) => ({
		value: course.id,
		label: course.name || course.title, // depending on your API response structure
	}));

	return (
		<div className="space-y-6">
			{/* Alert Message */}
			{alert.type && (
				<div
					className={`p-4 mb-4 rounded ${
						alert.type === "success"
							? "bg-green-50 text-green-800"
							: "bg-red-50 text-red-800"
					}`}
				>
					{alert.message}
				</div>
			)}

			{/* Header */}
			<div className="flex justify-between items-center">
				<h1 className="text-2xl font-bold">Course Enrollments</h1>
				<button
					onClick={() => {
						setIsEditing(false);
						setCurrentEnrollment(null);
						setIsModalOpen(true);
					}}
					className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
				>
					Add New Enrollment
				</button>
			</div>

			{/* Content */}
			{isLoading ? (
				<div className="flex items-center justify-center p-8">
					<div className="flex items-center space-x-2">
						<i className="bi bi-arrow-clockwise animate-spin text-blue-600"></i>
						<span>Loading Enrollments...</span>
					</div>
				</div>
			) : (
				<EnrollmentsTable
					enrollments={enrollments.map(getEnrollmentDetails)}
					handleEdit={(enrollment) => {
						setCurrentEnrollment(enrollment);
						setIsEditing(true);
						setIsModalOpen(true);
					}}
					getScoreBadgeClass={getScoreBadgeClass}
					page={page}
					totalPages={totalPages}
					setPage={setPage}
				/>
			)}

			{/* Modal */}
			<EnrollmentDialog
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				enrollment={currentEnrollment}
				applicants={applicants}
				courses={courses}
				institutions={institutions}
				isEditing={isEditing}
				handleSubmit={handleSubmit}
				SCORE_TYPES={SCORE_TYPES}
			/>
		</div>
	);
};

export default Enrollments;
