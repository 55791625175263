import React, { useState, useEffect } from "react";
import JobDialog from "../../components/atsflow/JobDialog";
import JobsTable from "../../components/atsflow/JobsTable";

const JOB_TYPES = [
	{ value: "full_time", label: "Full Time" },
	{ value: "part_time", label: "Part Time" },
	{ value: "contract", label: "Contract" },
	{ value: "temporary", label: "Temporary" },
];

const JOB_STATUS = [
	{ value: "draft", label: "Draft" },
	{ value: "open", label: "Open" },
	{ value: "filled", label: "Filled" },
	{ value: "closed", label: "Closed" },
];

const PROVINCES = [
	"ON",
	"BC",
	"AB",
	"SK",
	"MB",
	"QC",
	"NB",
	"NS",
	"PE",
	"NL",
	"YT",
	"NT",
	"NU",
];

const Jobs = () => {
	const [jobs, setJobs] = useState([]);
	const [employers, setEmployers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentJob, setCurrentJob] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [alert, setAlert] = useState({ type: "", message: "" });
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const [jobsResponse, employersResponse] = await Promise.all([
				fetch("/ats-jobs/"),
				fetch("/ats-employers/"),
			]);

			if (!jobsResponse.ok) throw new Error("Failed to fetch jobs");
			if (!employersResponse.ok) throw new Error("Failed to fetch employers");

			const jobsData = await jobsResponse.json();
			const employersData = await employersResponse.json();

			setJobs(jobsData);
			setEmployers(employersData);
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to load data. Please try again.",
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const validateJobData = (data) => {
		const errors = [];
		const required = [
			"employer_id",
			"title",
			"street",
			"city",
			"province",
			"postal_code",
			"job_type",
			"status",
		];

		required.forEach((field) => {
			if (!data[field]) {
				errors.push(`${field.replace(/_/g, " ")} is required`);
			}
		});

		if (data.positions_available && Number(data.positions_available) < 1) {
			errors.push("Positions available must be greater than 0");
		}

		if (data.start_date && data.application_deadline) {
			const start = new Date(data.start_date);
			const deadline = new Date(data.application_deadline);
			if (deadline > start) {
				errors.push("Application deadline must be before start date");
			}
		}

		// Postal code validation
		const postalRegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
		if (data.postal_code && !postalRegex.test(data.postal_code)) {
			errors.push("Invalid postal code format (e.g., A1A 1A1)");
		}

		return errors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const jobData = Object.fromEntries(formData.entries());

		// Parse requirements JSON field
		try {
			jobData.requirements = JSON.stringify(
				formData
					.getAll("requirements")
					.map((req) => ({
						type: "training",
						requirement: req,
					}))
					.filter((req) => req.requirement),
			);
		} catch (error) {
			jobData.requirements = "[]";
		}

		const errors = validateJobData(jobData);
		if (errors.length > 0) {
			setAlert({
				type: "error",
				message: `Validation errors: ${errors.join(", ")}`,
			});
			return;
		}

		try {
			const response = await fetch(`/ats-jobs/${currentJob?.id || ""}`, {
				method: isEditing ? "PUT" : "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(jobData),
			});

			if (!response.ok) throw new Error("Failed to save job");

			setAlert({
				type: "success",
				message: `Job posting successfully ${
					isEditing ? "updated" : "created"
				}`,
			});
			setIsModalOpen(false);
			fetchData();
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to save job posting. Please try again.",
			});
		}
	};

	return (
		<div className="space-y-6">
			{/* Alert Message */}
			{alert.type && (
				<div
					className={`p-4 mb-4 rounded ${
						alert.type === "success"
							? "bg-green-50 text-green-800"
							: "bg-red-50 text-red-800"
					}`}
				>
					{alert.message}
				</div>
			)}

			{/* Header */}
			<div className="flex justify-between items-center">
				<h1 className="text-2xl font-bold">Job Postings</h1>
				<button
					onClick={() => {
						setIsEditing(false);
						setCurrentJob(null);
						setIsModalOpen(true);
					}}
					className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
				>
					Add New Job
				</button>
			</div>

			{/* Content */}
			{isLoading ? (
				<div className="flex items-center justify-center p-8">
					<div className="flex items-center space-x-2">
						<i className="bi bi-arrow-clockwise animate-spin text-blue-600"></i>
						<span>Loading Jobs...</span>
					</div>
				</div>
			) : (
				<JobsTable
					jobs={jobs}
					employers={employers}
					handleEdit={(job) => {
						setCurrentJob(job);
						setIsEditing(true);
						setIsModalOpen(true);
					}}
					page={page}
					totalPages={totalPages}
					setPage={setPage}
				/>
			)}

			{/* Modal */}
			<JobDialog
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				job={currentJob}
				isEditing={isEditing}
				handleSubmit={handleSubmit}
				employers={employers}
				JOB_TYPES={JOB_TYPES}
				JOB_STATUS={JOB_STATUS}
				PROVINCES={PROVINCES}
			/>
		</div>
	);
};

export default Jobs;
