import React, { useState } from "react";
import { Dialog } from "@headlessui/react";

const PlacementDialog = ({
	isOpen,
	onClose,
	placement,
	applicants,
	jobs,
	employers,
	isEditing,
	handleSubmit,
}) => {
	const [selectedEmployerId, setSelectedEmployerId] = useState(
		placement?.job?.employer_id || "",
	);

	const filteredJobs = selectedEmployerId
		? jobs.filter((job) => job.employer_id === Number(selectedEmployerId))
		: jobs;

	return (
		<Dialog open={isOpen} onClose={onClose} className="relative z-50">
			<div className="fixed inset-0 bg-black/30" aria-hidden="true" />

			<div className="fixed inset-0 flex items-center justify-center p-4">
				<Dialog.Panel className="mx-auto max-w-4xl w-full rounded bg-white p-6 shadow-xl max-h-[90vh] overflow-y-auto">
					<div className="flex justify-between items-center mb-6">
						<Dialog.Title className="text-xl font-medium">
							{isEditing ? "Edit Placement" : "Add New Placement"}
						</Dialog.Title>
						<button
							onClick={onClose}
							className="text-gray-400 hover:text-gray-500"
						>
							<span className="sr-only">Close</span>
							<svg
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>

					<form onSubmit={handleSubmit} className="space-y-6">
						{/* Applicant Selection */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Applicant Information</h3>
							<div className="grid grid-cols-1 gap-4">
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Select Applicant
									</label>
									<select
										name="applicant_id"
										defaultValue={placement?.applicant_id || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									>
										<option value="">Select Applicant</option>
										{applicants.map((applicant) => (
											<option key={applicant.id} value={applicant.id}>
												{applicant.firstname} {applicant.lastname} -{" "}
												{applicant.email}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>

						{/* Employer and Job Selection */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Position Information</h3>
							<div className="grid grid-cols-1 gap-4">
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Select Employer
									</label>
									<select
										value={selectedEmployerId}
										onChange={(e) => setSelectedEmployerId(e.target.value)}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									>
										<option value="">Select Employer</option>
										{employers.map((employer) => (
											<option key={employer.id} value={employer.id}>
												{employer.business_name}
											</option>
										))}
									</select>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Select Position
									</label>
									<select
										name="job_id"
										defaultValue={placement?.job_id || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									>
										<option value="">Select Position</option>
										{filteredJobs.map((job) => (
											<option key={job.id} value={job.id}>
												{job.title} - {job.city}, {job.province}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>

						{/* Placement Details */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Placement Details</h3>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Hired Date
									</label>
									<input
										type="date"
										name="hired_date"
										defaultValue={
											placement?.hired_date ||
											new Date().toISOString().split("T")[0]
										}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									/>
								</div>
								<div className="flex items-center">
									<input
										type="checkbox"
										name="active"
										defaultChecked={placement?.active ?? true}
										className="h-4 w-4 text-blue-600 rounded border-gray-300"
									/>
									<label className="ml-2 text-sm text-gray-700">
										Active Placement
									</label>
								</div>
							</div>
						</div>

						{/* Placement Preview */}
						{isEditing && (
							<div className="bg-gray-50 p-4 rounded-lg">
								<h3 className="font-medium mb-4">Placement History</h3>
								<div className="grid grid-cols-2 gap-4 text-sm">
									<div>
										<span className="font-medium">Created:</span>
										<div className="text-gray-600">
											{new Date(placement.created_at).toLocaleString()}
										</div>
									</div>
									<div>
										<span className="font-medium">Last Updated:</span>
										<div className="text-gray-600">
											{new Date(placement.updated_at).toLocaleString()}
										</div>
									</div>
								</div>
							</div>
						)}

						{/* Form Actions */}
						<div className="flex justify-end space-x-3 pt-4 border-t">
							<button
								type="button"
								onClick={onClose}
								className="px-4 py-2 text-gray-700 bg-gray-100 rounded hover:bg-gray-200"
							>
								Cancel
							</button>
							<button
								type="submit"
								className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
							>
								{isEditing ? "Update" : "Create"} Placement
							</button>
						</div>
					</form>
				</Dialog.Panel>
			</div>
		</Dialog>
	);
};

export default PlacementDialog;
