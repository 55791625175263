import React, { useState, useEffect } from "react";
import EmployerDialog from "../../components/atsflow/EmployerDialog";
import EmployersTable from "../../components/atsflow/EmployersTable";

const COMPANY_SIZES = ["1-10", "11-50", "51-200", "201-500", "500+"];

const PROVINCES = [
	"ON",
	"BC",
	"AB",
	"SK",
	"MB",
	"QC",
	"NB",
	"NS",
	"PE",
	"NL",
	"YT",
	"NT",
	"NU",
];

const Employers = () => {
	const [employers, setEmployers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentEmployer, setCurrentEmployer] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [alert, setAlert] = useState({ type: "", message: "" });
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const fetchEmployers = async () => {
		try {
			const response = await fetch("/ats-employers/");
			if (!response.ok) throw new Error("Failed to fetch employers");
			const data = await response.json();
			setEmployers(data);
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to load employers. Please try again.",
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchEmployers();
	}, []);

	const validateEmployerData = (data) => {
		const errors = [];
		const required = [
			"business_name",
			"street",
			"city",
			"province",
			"postal_code",
			"primary_contact_name",
			"primary_contact_phone",
			"primary_contact_email",
		];

		required.forEach((field) => {
			if (!data[field]) {
				errors.push(`${field.replace(/_/g, " ")} is required`);
			}
		});

		// Email validation
		const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		if (
			data.primary_contact_email &&
			!emailRegex.test(data.primary_contact_email)
		) {
			errors.push("Invalid email format");
		}

		// Phone validation
		const phoneRegex = /^\d{10}$/;
		if (
			data.primary_contact_phone &&
			!phoneRegex.test(data.primary_contact_phone.replace(/\D/g, ""))
		) {
			errors.push("Phone number must be 10 digits");
		}

		// Postal code validation
		const postalRegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
		if (data.postal_code && !postalRegex.test(data.postal_code)) {
			errors.push("Invalid postal code format (e.g., A1A 1A1)");
		}

		return errors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const employerData = Object.fromEntries(formData.entries());

		const errors = validateEmployerData(employerData);
		if (errors.length > 0) {
			setAlert({
				type: "error",
				message: `Validation errors: ${errors.join(", ")}`,
			});
			return;
		}

		try {
			const response = await fetch(
				`/ats-employers/${currentEmployer?.id || ""}`,
				{
					method: isEditing ? "PUT" : "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(employerData),
				},
			);

			if (!response.ok) throw new Error("Failed to save employer");

			setAlert({
				type: "success",
				message: `Employer successfully ${isEditing ? "updated" : "created"}`,
			});
			setIsModalOpen(false);
			fetchEmployers();
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to save employer. Please try again.",
			});
		}
	};

	const formatDate = (dateString) => {
		return new Date(dateString).toLocaleString();
	};

	return (
		<div className="space-y-6">
			{/* Alert Message */}
			{alert.type && (
				<div
					className={`p-4 mb-4 rounded ${
						alert.type === "success"
							? "bg-green-50 text-green-800"
							: "bg-red-50 text-red-800"
					}`}
				>
					{alert.message}
				</div>
			)}

			{/* Header */}
			<div className="flex justify-between items-center">
				<h1 className="text-2xl font-bold">Employers</h1>
				<button
					onClick={() => {
						setIsEditing(false);
						setCurrentEmployer(null);
						setIsModalOpen(true);
					}}
					className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
				>
					Add New Employer
				</button>
			</div>

			{/* Content */}
			{isLoading ? (
				<div className="flex items-center justify-center p-8">
					<div className="flex items-center space-x-2">
						<i className="bi bi-arrow-clockwise animate-spin text-blue-600"></i>
						<span>Loading Employers...</span>
					</div>
				</div>
			) : (
				<EmployersTable
					employers={employers}
					handleEdit={(employer) => {
						setCurrentEmployer(employer);
						setIsEditing(true);
						setIsModalOpen(true);
					}}
					page={page}
					totalPages={totalPages}
					setPage={setPage}
				/>
			)}

			{/* Modal */}
			<EmployerDialog
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				employer={currentEmployer}
				isEditing={isEditing}
				handleSubmit={handleSubmit}
				COMPANY_SIZES={COMPANY_SIZES}
				PROVINCES={PROVINCES}
			/>
		</div>
	);
};

export default Employers;
