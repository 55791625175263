import React, { useState, useEffect } from "react";

const PROVINCES = [
	"ON",
	"BC",
	"AB",
	"SK",
	"MB",
	"QC",
	"NB",
	"NS",
	"PE",
	"NL",
	"YT",
	"NT",
	"NU",
];
const WORK_STATUS = ["N", "F", "PT", "Other", "Not Provided"];
const DL_STATUS = [
	"Unlicensed",
	"Active",
	"Suspended",
	"Other",
	"Not Provided",
];
const DL_TYPES = ["G", "G1", "G2", "Other", "Not Provided"];
const IMMIGRATION_STATUS = [
	"Student",
	"Work Permit",
	"Study Permit",
	"Temporary Resident",
	"Permanent Resident",
	"Visitor",
	"Refugee",
	"Other",
	"Not Provided",
];
const REFERENCE_SOURCES = [
	"Website",
	"Social Media",
	"Friend/Family",
	"Job Fair",
	"Employment Agency",
	"Other",
	"Not Provided",
];

const ApplicantController = () => {
	const [applicants, setApplicants] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentApplicant, setCurrentApplicant] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [alert, setAlert] = useState({ type: "", message: "" });
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const fetchApplicants = async () => {
		try {
			const response = await fetch("/ats-applicants/");
			if (!response.ok) throw new Error("Failed to fetch applicants");
			const data = await response.json();
			setApplicants(data);
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to load applicants. Please try again.",
			});
		} finally {
			setIsLoading(false);
		}
	};

	const validateApplicantData = (data) => {
		const errors = [];

		// Email validation
		const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		if (!emailRegex.test(data.email)) {
			errors.push("Invalid email format");
		}

		// Phone validation
		const phoneRegex = /^[0-9]{10}$/;
		if (!phoneRegex.test(data.phone)) {
			errors.push("Phone number must be 10 digits");
		}

		// Province validation
		if (!PROVINCES.includes(data.province)) {
			errors.push("Invalid province");
		}

		// Required fields validation
		const required = ["firstname", "lastname", "email", "phone", "province"];
		required.forEach((field) => {
			if (!data[field]) {
				errors.push(`${field} is required`);
			}
		});

		return errors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const applicantData = Object.fromEntries(formData.entries());

		// Add system fields
		applicantData.status_date = new Date().toISOString().split("T")[0];
		if (!isEditing) {
			applicantData.entry_date = new Date().toISOString();
		}

		// Validate data
		const errors = validateApplicantData(applicantData);
		if (errors.length > 0) {
			setAlert({
				type: "error",
				message: `Validation errors: ${errors.join(", ")}`,
			});
			return;
		}

		try {
			const response = await fetch(
				`/ats/applicants/${currentApplicant?.id || ""}`,
				{
					method: isEditing ? "PUT" : "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(applicantData),
				},
			);

			if (!response.ok) throw new Error("Failed to save applicant");

			setAlert({
				type: "success",
				message: `Applicant successfully ${isEditing ? "updated" : "created"}`,
			});
			setIsModalOpen(false);
			fetchApplicants();
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to save applicant. Please try again.",
			});
		}
	};

	const renderForm = () => (
		<form onSubmit={handleSubmit} className="space-y-6">
			<div className="grid grid-cols-2 gap-4">
				{/* Personal Information */}
				<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
					<h3 className="font-medium mb-4">Personal Information</h3>
					<div className="grid grid-cols-2 gap-4">
						<div>
							<label className="block text-sm font-medium text-gray-700">
								First Name
							</label>
							<input
								type="text"
								name="firstname"
								defaultValue={currentApplicant?.firstname || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Last Name
							</label>
							<input
								type="text"
								name="lastname"
								defaultValue={currentApplicant?.lastname || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Email
							</label>
							<input
								type="email"
								name="email"
								defaultValue={currentApplicant?.email || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Phone
							</label>
							<input
								type="tel"
								name="phone"
								defaultValue={currentApplicant?.phone || ""}
								pattern="[0-9]{10}"
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Date of Birth
							</label>
							<input
								type="date"
								name="dob"
								defaultValue={currentApplicant?.dob || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Gender
							</label>
							<select
								name="gender"
								defaultValue={currentApplicant?.gender || "Prefer Not To Say"}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							>
								<option value="M">Male</option>
								<option value="F">Female</option>
								<option value="Other">Other</option>
								<option value="Prefer Not To Say">Prefer Not To Say</option>
							</select>
						</div>
					</div>
				</div>

				{/* Personal Details */}
				<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
					<h3 className="font-medium mb-4">Personal Details</h3>
					<div className="grid grid-cols-2 gap-4">
						<div>
							<label className="block text-sm font-medium text-gray-700">
								SIN
							</label>
							<input
								type="text"
								name="sin"
								defaultValue={currentApplicant?.sin || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								pattern="[0-9]{9}"
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Education
							</label>
							<input
								type="text"
								name="education"
								defaultValue={currentApplicant?.education || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Reference Source
							</label>
							<select
								name="reference"
								defaultValue={currentApplicant?.reference || "Not Provided"}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							>
								{REFERENCE_SOURCES?.map((source) => (
									<option key={source} value={source}>
										{source}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>

				{/* Address Information */}
				<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
					<h3 className="font-medium mb-4">Address Information</h3>
					<div className="grid grid-cols-2 gap-4">
						<div className="col-span-2">
							<label className="block text-sm font-medium text-gray-700">
								Street
							</label>
							<input
								type="text"
								name="street"
								defaultValue={currentApplicant?.street || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								City
							</label>
							<input
								type="text"
								name="city"
								defaultValue={currentApplicant?.city || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Province
							</label>
							<select
								name="province"
								defaultValue={currentApplicant?.province || "ON"}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							>
								{PROVINCES?.map((province) => (
									<option key={province} value={province}>
										{province}
									</option>
								))}
							</select>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Postal Code
							</label>
							<input
								type="text"
								name="postal_code"
								defaultValue={currentApplicant?.postal_code || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								pattern="[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]"
							/>
						</div>
					</div>
				</div>

				{/* Immigration Information */}
				<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
					<h3 className="font-medium mb-4">Immigration Information</h3>
					<div className="grid grid-cols-2 gap-4">
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Immigration Status
							</label>
							<select
								name="immigration_status"
								defaultValue={
									currentApplicant?.immigration_status || "Not Provided"
								}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							>
								{IMMIGRATION_STATUS?.map((status) => (
									<option key={status} value={status}>
										{status}
									</option>
								))}
							</select>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Other Immigration Status
							</label>
							<input
								type="text"
								name="other_immigration_status"
								defaultValue={currentApplicant?.other_immigration_status || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Canada Landed Date
							</label>
							<input
								type="date"
								name="canada_landed_date"
								defaultValue={currentApplicant?.canada_landed_date || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							/>
						</div>
					</div>
				</div>

				{/* Employment & License Information */}
				<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
					<h3 className="font-medium mb-4">Employment & License Information</h3>
					<div className="grid grid-cols-2 gap-4">
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Current Work Status
							</label>
							<select
								name="current_work"
								defaultValue={currentApplicant?.current_work || "Not Provided"}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							>
								{WORK_STATUS?.map((status) => (
									<option key={status} value={status}>
										{status}
									</option>
								))}
							</select>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Driver's License Number
							</label>
							<input
								type="text"
								name="drivers_license"
								defaultValue={currentApplicant?.drivers_license || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Driver's License Status
							</label>
							<select
								name="dl_status"
								defaultValue={currentApplicant?.dl_status || "Not Provided"}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							>
								{DL_STATUS?.map((status) => (
									<option key={status} value={status}>
										{status}
									</option>
								))}
							</select>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Driver's License Type
							</label>
							<select
								name="dl_type"
								defaultValue={currentApplicant?.dl_type || "Not Provided"}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							>
								{DL_TYPES?.map((type) => (
									<option key={type} value={type}>
										{type}
									</option>
								))}
							</select>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Driver's License Expiry
							</label>
							<input
								type="date"
								name="dl_expiry"
								defaultValue={currentApplicant?.dl_expiry || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
							/>
						</div>
					</div>
				</div>

				{/* Fields of Interest */}
				{currentApplicant?.fields_of_interest &&
					(() => {
						try {
							const fields = JSON.parse(currentApplicant.fields_of_interest);
							if (!Array.isArray(fields)) return null;
							return (
								<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
									<h3 className="font-medium mb-4">Fields of Interest</h3>
									<div className="grid grid-cols-2 gap-4">
										{fields.map((field, index) => (
											<div key={index} className="flex items-center space-x-2">
												<span className="text-sm font-medium">
													{field.field}:
												</span>
												<span
													className={`px-2 py-1 rounded-full text-xs ${
														field.experience === "Y"
															? "bg-green-100 text-green-800"
															: "bg-gray-100 text-gray-800"
													}`}
												>
													{field.experience === "Y"
														? "Experienced"
														: "No Experience"}
												</span>
											</div>
										))}
									</div>
								</div>
							);
						} catch (e) {
							console.error("Failed to parse fields_of_interest:", e);
							return null;
						}
					})()}

				{/* Course Enrollment Information */}
				{currentApplicant?.enrollment && (
					<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
						<h3 className="font-medium mb-4">Course Enrollment</h3>
						<div className="grid grid-cols-2 gap-4">
							<div>
								<label className="block text-sm font-medium text-gray-700">
									Enrollment Date
								</label>
								<div className="text-sm text-gray-900">
									{new Date(
										currentApplicant.enrollment.enrollment_date,
									).toLocaleDateString()}
								</div>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-700">
									Assessment Score
								</label>
								<div
									className={`text-sm ${
										currentApplicant.enrollment.score === "Fail"
											? "text-red-600"
											: "text-green-600"
									}`}
								>
									{currentApplicant.enrollment.score}
								</div>
							</div>
							<div className="col-span-2">
								<label className="block text-sm font-medium text-gray-700">
									Assessment Notes
								</label>
								<div className="text-sm text-gray-900">
									{currentApplicant.enrollment.assessment}
								</div>
							</div>

							{/* Course Details */}
							<div className="col-span-2 mt-4">
								<h4 className="font-medium mb-2">Course Details</h4>
								<div className="grid grid-cols-2 gap-4">
									<div>
										<label className="block text-sm font-medium text-gray-700">
											Course Name
										</label>
										<div className="text-sm text-gray-900">
											{currentApplicant.enrollment.course.name}
										</div>
									</div>
									<div>
										<label className="block text-sm font-medium text-gray-700">
											Course Type
										</label>
										<div className="text-sm text-gray-900 capitalize">
											{currentApplicant.enrollment.course.type}
										</div>
									</div>
									<div className="col-span-2">
										<label className="block text-sm font-medium text-gray-700">
											Description
										</label>
										<div className="text-sm text-gray-900">
											{currentApplicant.enrollment.course.description}
										</div>
									</div>
									<div>
										<label className="block text-sm font-medium text-gray-700">
											Start Date
										</label>
										<div className="text-sm text-gray-900">
											{new Date(
												currentApplicant.enrollment.course.start_date,
											).toLocaleDateString()}
										</div>
									</div>
									<div>
										<label className="block text-sm font-medium text-gray-700">
											End Date
										</label>
										<div className="text-sm text-gray-900">
											{new Date(
												currentApplicant.enrollment.course.end_date,
											).toLocaleDateString()}
										</div>
									</div>

									{/* Institution Details */}
									<div className="col-span-2 mt-4">
										<h4 className="font-medium mb-2">Training Institution</h4>
										<div className="text-sm">
											<div className="font-medium">
												{currentApplicant.enrollment.course.institution.name}
											</div>
											<div>
												{currentApplicant.enrollment.course.institution.street}
											</div>
											<div>
												{currentApplicant.enrollment.course.institution.city},{" "}
												{
													currentApplicant.enrollment.course.institution
														.province
												}
											</div>
											<div>
												{
													currentApplicant.enrollment.course.institution
														.postal_code
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				{/* Job Placement Information */}
				{currentApplicant?.placement && (
					<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
						<h3 className="font-medium mb-4">Job Placement</h3>
						<div className="grid grid-cols-2 gap-4">
							<div>
								<label className="block text-sm font-medium text-gray-700">
									Hired Date
								</label>
								<div className="text-sm text-gray-900">
									{new Date(
										currentApplicant.placement.hired_date,
									).toLocaleDateString()}
								</div>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-700">
									Status
								</label>
								<div
									className={`text-sm ${
										currentApplicant.placement.active === "1"
											? "text-green-600"
											: "text-red-600"
									}`}
								>
									{currentApplicant.placement.active === "1"
										? "Active"
										: "Inactive"}
								</div>
							</div>

							{/* Job Details */}
							<div className="col-span-2 mt-4">
								<h4 className="font-medium mb-2">Job Details</h4>
								<div className="grid grid-cols-2 gap-4">
									<div>
										<label className="block text-sm font-medium text-gray-700">
											Position
										</label>
										<div className="text-sm text-gray-900">
											{currentApplicant.placement.job.title}
										</div>
									</div>
									<div>
										<label className="block text-sm font-medium text-gray-700">
											Job Type
										</label>
										<div className="text-sm text-gray-900 capitalize">
											{currentApplicant.placement.job.job_type}
										</div>
									</div>
									<div className="col-span-2">
										<label className="block text-sm font-medium text-gray-700">
											Description
										</label>
										<div className="text-sm text-gray-900">
											{currentApplicant.placement.job.description}
										</div>
									</div>
									<div>
										<label className="block text-sm font-medium text-gray-700">
											Salary Range
										</label>
										<div className="text-sm text-gray-900">
											{currentApplicant.placement.job.salary_range}
										</div>
									</div>
									<div>
										<label className="block text-sm font-medium text-gray-700">
											Benefits
										</label>
										<div className="text-sm text-gray-900">
											{currentApplicant.placement.job.benefits}
										</div>
									</div>

									{/* Employer Details */}
									<div className="col-span-2 mt-4">
										<h4 className="font-medium mb-2">Employer Information</h4>
										<div className="text-sm">
											<div className="font-medium">
												{currentApplicant.placement.job.employer.business_name}
											</div>
											<div className="text-gray-600">
												{currentApplicant.placement.job.employer.legal_name}
											</div>
											<div>
												{currentApplicant.placement.job.employer.street}
											</div>
											<div>
												{currentApplicant.placement.job.employer.city},{" "}
												{currentApplicant.placement.job.employer.province}
											</div>
											<div>
												{currentApplicant.placement.job.employer.postal_code}
											</div>
											<div className="mt-2">
												<span className="font-medium">Contact: </span>
												{
													currentApplicant.placement.job.employer
														.primary_contact_name
												}
												,{" "}
												{
													currentApplicant.placement.job.employer
														.primary_contact_title
												}
											</div>
											<div>
												{
													currentApplicant.placement.job.employer
														.primary_contact_phone
												}
											</div>
											<div>
												{
													currentApplicant.placement.job.employer
														.primary_contact_email
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				{/* Documents */}
				{currentApplicant?.documents &&
					(() => {
						try {
							const docs = JSON.parse(currentApplicant.documents);
							if (!Array.isArray(docs)) return null;
							return (
								<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
									<h3 className="font-medium mb-4">Documents</h3>
									<div className="grid grid-cols-2 gap-4">
										{docs.map((doc, index) => (
											<div key={index} className="flex items-center space-x-2">
												<span className="text-sm font-medium">
													{doc.document_type}:
												</span>
												<span className="text-sm text-gray-600">
													Upload ID: {doc.upload}
												</span>
											</div>
										))}
									</div>
								</div>
							);
						} catch (e) {
							console.error("Failed to parse documents:", e);
							return null;
						}
					})()}
			</div>

			<div className="flex justify-end space-x-2 pt-4">
				<button
					type="button"
					onClick={() => setIsModalOpen(false)}
					className="px-4 py-2 text-gray-700 bg-gray-100 rounded hover:bg-gray-200"
				>
					Cancel
				</button>
				<button
					type="submit"
					className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
				>
					{isEditing ? "Update" : "Create"} Applicant
				</button>
			</div>
		</form>
	);

	useEffect(() => {
		fetchApplicants();
	}, []);

	const Modal = ({ isOpen, onClose, title, children }) => {
		if (!isOpen) return null;

		return (
			<div className="fixed inset-0 z-50 overflow-y-auto">
				<div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:p-0">
					<div className="fixed inset-0 transition-opacity" aria-hidden="true">
						<div className="absolute inset-0 bg-gray-500 opacity-75"></div>
					</div>

					<div className="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
						<div className="flex justify-between items-center mb-4">
							<h3 className="text-lg font-medium text-gray-900">{title}</h3>
							<button
								onClick={onClose}
								className="text-gray-400 hover:text-gray-500"
							>
								<span className="sr-only">Close</span>
								<svg
									className="h-6 w-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
						<div className="mt-4">{children}</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			{/* Alert Message */}
			{alert.type && (
				<div
					className={`p-4 mb-4 rounded ${
						alert.type === "success"
							? "bg-green-50 text-green-800"
							: "bg-red-50 text-red-800"
					}`}
				>
					{alert.message}
				</div>
			)}

			{/* Header */}
			<div className="flex justify-between items-center mb-6">
				<h1 className="text-2xl font-bold">Applicants</h1>
				<button
					onClick={() => {
						setIsEditing(false);
						setCurrentApplicant(null);
						setIsModalOpen(true);
					}}
					className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
				>
					Add New Applicant
				</button>
			</div>

			{/* Table */}
			{isLoading ? (
				<div className="flex items-center justify-center p-8">
					<div className="flex items-center space-x-2">
						<i className="bi bi-arrow-clockwise animate-spin text-blue-600"></i>
						<span>Loading Applicants...</span>
					</div>
				</div>
			) : (
				<div className="overflow-x-auto">
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
									Name
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
									Contact
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
									Location
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
									Work Status
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
									Entry Date
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
									Status
								</th>
								<th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">
									Actions
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{applicants.map((applicant) => (
								<tr key={applicant.id} className="hover:bg-gray-50">
									<td className="px-6 py-4">{`${applicant.firstname} ${applicant.lastname}`}</td>
									<td className="px-6 py-4">
										<div>{applicant.email}</div>
										<div className="text-sm text-gray-500">
											{applicant.phone}
										</div>
									</td>
									<td className="px-6 py-4">{`${applicant.city || "-"}, ${
										applicant.province
									}`}</td>
									<td className="px-6 py-4">
										<span
											className={`inline-flex px-2 py-1 rounded-full text-sm ${
												applicant.current_work === "F"
													? "bg-green-100 text-green-800"
													: applicant.current_work === "PT"
													? "bg-yellow-100 text-yellow-800"
													: "bg-gray-100 text-gray-800"
											}`}
										>
											{applicant.current_work}
										</span>
									</td>
									<td className="px-6 py-4">
										{new Date(applicant.entry_date).toLocaleDateString()}
									</td>
									<td className="px-6 py-4">
										<div className="flex flex-col gap-1">
											<span
												className={`inline-flex px-2 py-1 rounded-full text-sm ${
													applicant.status?.description === "Applicant Is Hired"
														? "bg-green-100 text-green-800"
														: applicant.status?.description === "Failed Course"
														? "bg-red-100 text-red-800"
														: "bg-gray-100 text-gray-800"
												}`}
											>
												{applicant.status?.description || "Unknown"}
											</span>
											<div className="flex gap-1">
												{/* Only show enrolled tag if enrollment exists and has a valid course */}
												{applicant.enrollment &&
													applicant.enrollment.course_id &&
													applicant.enrollment.enrollment_date && (
														<span className="inline-flex px-2 py-1 rounded-full text-xs bg-blue-100 text-blue-800">
															Enrolled
														</span>
													)}
												{/* Only show placed tag if placement exists and has a valid job */}
												{applicant.placement != "" && (
													<span className="inline-flex px-2 py-1 rounded-full text-xs bg-green-100 text-green-800">
														Placed
													</span>
												)}
											</div>
										</div>
									</td>
									<td className="px-6 py-4 text-right">
										<button
											onClick={() => {
												setIsEditing(true);
												setCurrentApplicant(applicant);
												setIsModalOpen(true);
											}}
											className="text-blue-600 hover:text-blue-900"
										>
											Edit
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>

					{/* Pagination */}
					<div className="flex justify-between items-center mt-4">
						<button
							onClick={() => setPage((prev) => Math.max(1, prev - 1))}
							disabled={page === 1}
							className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 disabled:opacity-50"
						>
							Previous
						</button>
						<span className="text-sm text-gray-700">
							Page {page} of {totalPages}
						</span>
						<button
							onClick={() => setPage((prev) => Math.min(totalPages, prev + 1))}
							disabled={page === totalPages}
							className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 disabled:opacity-50"
						>
							Next
						</button>
					</div>
				</div>
			)}

			<Modal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				title={isEditing ? "Edit Applicant" : "Add New Applicant"}
			>
				{renderForm()}
			</Modal>
		</div>
	);
};

export default ApplicantController;
