import React from "react";

const EnrollmentsTable = ({
	enrollments,
	handleEdit,
	getScoreBadgeClass,
	page,
	totalPages,
	setPage,
}) => {
	const formatDate = (date) => {
		return new Date(date).toLocaleDateString();
	};

	return (
		<div className="space-y-4">
			<div className="bg-white rounded-lg shadow overflow-hidden">
				<div className="overflow-x-auto">
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Student
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Course
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Institution
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Enrollment Date
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Assessment
								</th>
								<th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
									Actions
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{enrollments.map((enrollment) => (
								<tr key={enrollment.id} className="hover:bg-gray-50">
									<td className="px-6 py-4">
										<div className="font-medium text-gray-900">
											{enrollment.applicant?.firstname}{" "}
											{enrollment.applicant?.lastname}
										</div>
										<div className="text-sm text-gray-500">
											{enrollment.applicant?.email}
										</div>
									</td>
									<td className="px-6 py-4">
										<div className="font-medium">{enrollment.course?.name}</div>
										<div className="text-sm text-gray-500 capitalize">
											{enrollment.course?.type}
										</div>
									</td>
									<td className="px-6 py-4">
										<div className="font-medium">
											{enrollment.institution?.name}
										</div>
										<div className="text-sm text-gray-500">
											{enrollment.institution?.city},{" "}
											{enrollment.institution?.province}
										</div>
									</td>
									<td className="px-6 py-4">
										<div>{formatDate(enrollment.enrollment_date)}</div>
										<div className="text-sm text-gray-500">
											Created: {formatDate(enrollment.created_at)}
										</div>
									</td>
									<td className="px-6 py-4">
										<div className="flex flex-col gap-2">
											{enrollment.assessment && (
												<div className="text-sm text-gray-600">
													{enrollment.assessment}
												</div>
											)}
											{enrollment.score && (
												<span
													className={`inline-flex px-2 py-1 rounded-full text-xs font-medium ${getScoreBadgeClass(
														enrollment.score,
													)}`}
												>
													{enrollment.score}
												</span>
											)}
										</div>
									</td>
									<td className="px-6 py-4 text-right">
										<button
											onClick={() => handleEdit(enrollment)}
											className="text-blue-600 hover:text-blue-900"
										>
											Edit
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{/* Pagination */}
			<div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
				<div className="flex justify-between items-center w-full">
					<button
						onClick={() => setPage((p) => Math.max(1, p - 1))}
						disabled={page === 1}
						className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
					>
						Previous
					</button>
					<div className="text-sm text-gray-700">
						Page {page} of {totalPages}
					</div>
					<button
						onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
						disabled={page === totalPages}
						className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default EnrollmentsTable;
