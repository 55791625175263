import React from "react";
import { Dialog } from "@headlessui/react";

const JobDialog = ({
	isOpen,
	onClose,
	job,
	isEditing,
	handleSubmit,
	employers,
	JOB_TYPES,
	JOB_STATUS,
	PROVINCES,
}) => {
	const requirements = job?.requirements ? JSON.parse(job.requirements) : [];

	return (
		<Dialog open={isOpen} onClose={onClose} className="relative z-50">
			<div className="fixed inset-0 bg-black/30" aria-hidden="true" />

			<div className="fixed inset-0 flex items-center justify-center p-4">
				<Dialog.Panel className="mx-auto max-w-4xl w-full rounded bg-white p-6 shadow-xl max-h-[90vh] overflow-y-auto">
					<div className="flex justify-between items-center mb-6">
						<Dialog.Title className="text-xl font-medium">
							{isEditing ? "Edit Job Posting" : "Add New Job Posting"}
						</Dialog.Title>
						<button
							onClick={onClose}
							className="text-gray-400 hover:text-gray-500"
						>
							<span className="sr-only">Close</span>
							<svg
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>

					<form onSubmit={handleSubmit} className="space-y-6">
						{/* Basic Job Information */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Job Information</h3>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
								<div className="col-span-2">
									<label className="block text-sm font-medium text-gray-700">
										Employer
									</label>
									<select
										name="employer_id"
										defaultValue={job?.employer_id || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									>
										<option value="">Select Employer</option>
										{employers.map((employer) => (
											<option key={employer.id} value={employer.id}>
												{employer.business_name}
											</option>
										))}
									</select>
								</div>
								<div className="col-span-2">
									<label className="block text-sm font-medium text-gray-700">
										Job Title
									</label>
									<input
										type="text"
										name="title"
										defaultValue={job?.title || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Job Type
									</label>
									<select
										name="job_type"
										defaultValue={job?.job_type || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									>
										<option value="">Select Type</option>
										{JOB_TYPES.map((type) => (
											<option key={type.value} value={type.value}>
												{type.label}
											</option>
										))}
									</select>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Status
									</label>
									<select
										name="status"
										defaultValue={job?.status || "draft"}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									>
										{JOB_STATUS.map((status) => (
											<option key={status.value} value={status.value}>
												{status.label}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>

						{/* Job Details */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Job Details</h3>
							<div className="grid grid-cols-1 gap-4">
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Description
									</label>
									<textarea
										name="description"
										defaultValue={job?.description || ""}
										rows="4"
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Benefits
									</label>
									<textarea
										name="benefits"
										defaultValue={job?.benefits || ""}
										rows="2"
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Internal Notes
									</label>
									<textarea
										name="internal_notes"
										defaultValue={job?.internal_notes || ""}
										rows="2"
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								</div>
							</div>
						</div>

						{/* Requirements */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Requirements</h3>
							<div className="space-y-2">
								{[0, 1, 2, 3].map((index) => (
									<input
										key={index}
										type="text"
										name="requirements"
										defaultValue={requirements[index]?.requirement || ""}
										placeholder={`Requirement ${index + 1}`}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								))}
							</div>
						</div>

						{/* Location */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Location</h3>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
								<div className="col-span-2">
									<label className="block text-sm font-medium text-gray-700">
										Street Address
									</label>
									<input
										type="text"
										name="street"
										defaultValue={job?.street || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										City
									</label>
									<input
										type="text"
										name="city"
										defaultValue={job?.city || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Province
									</label>
									<select
										name="province"
										defaultValue={job?.province || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									>
										<option value="">Select Province</option>
										{PROVINCES.map((province) => (
											<option key={province} value={province}>
												{province}
											</option>
										))}
									</select>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Postal Code
									</label>
									<input
										type="text"
										name="postal_code"
										defaultValue={job?.postal_code || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										pattern="[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]"
										required
									/>
								</div>
							</div>
						</div>

						{/* Dates & Positions */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Schedule & Positions</h3>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Start Date
									</label>
									<input
										type="date"
										name="start_date"
										defaultValue={job?.start_date || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Application Deadline
									</label>
									<input
										type="date"
										name="application_deadline"
										defaultValue={job?.application_deadline || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Positions Available
									</label>
									<input
										type="number"
										name="positions_available"
										defaultValue={job?.positions_available || 1}
										min="1"
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Positions Filled
									</label>
									<input
										type="number"
										name="positions_filled"
										defaultValue={job?.positions_filled || 0}
										min="0"
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Salary Range
									</label>
									<input
										type="text"
										name="salary_range"
										defaultValue={job?.salary_range || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								</div>
							</div>
						</div>

						{/* Actions */}
						<div className="flex justify-end space-x-3 pt-4 border-t">
							<button
								type="button"
								onClick={onClose}
								className="px-4 py-2 text-gray-700 bg-gray-100 rounded hover:bg-gray-200"
							>
								Cancel
							</button>
							<button
								type="submit"
								className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
							>
								{isEditing ? "Update" : "Create"} Job
							</button>
						</div>
					</form>
				</Dialog.Panel>
			</div>
		</Dialog>
	);
};

export default JobDialog;
