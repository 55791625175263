import React from "react";

const EmployersTable = ({
	employers,
	handleEdit,
	page,
	totalPages,
	setPage,
}) => {
	return (
		<div className="space-y-4">
			<div className="bg-white rounded-lg shadow overflow-hidden">
				<div className="overflow-x-auto">
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Business
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Contact
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Location
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Industry
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Status
								</th>
								<th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
									Actions
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{employers.map((employer) => (
								<tr key={employer.id} className="hover:bg-gray-50">
									<td className="px-6 py-4">
										<div className="font-medium text-gray-900">
											{employer.business_name}
										</div>
										<div className="text-sm text-gray-500">
											{employer.legal_name}
										</div>
										<div className="text-xs text-gray-400">
											{employer.business_number}
										</div>
									</td>
									<td className="px-6 py-4">
										<div className="font-medium">
											{employer.primary_contact_name}
										</div>
										<div className="text-sm text-gray-500">
											{employer.primary_contact_title}
										</div>
										<div className="text-sm text-gray-500">
											{employer.primary_contact_email}
										</div>
									</td>
									<td className="px-6 py-4">
										<div>
											{employer.city}, {employer.province}
										</div>
										<div className="text-sm text-gray-500">
											{employer.postal_code}
										</div>
									</td>
									<td className="px-6 py-4">
										<div>{employer.industry_sector}</div>
										<div className="text-sm text-gray-500">
											Size: {employer.company_size}
										</div>
									</td>
									<td className="px-6 py-4">
										<div className="flex flex-col gap-1">
											<span
												className={`inline-flex px-2 py-1 rounded-full text-xs font-medium ${
													employer.active
														? "bg-green-100 text-green-800"
														: "bg-gray-100 text-gray-800"
												}`}
											>
												{employer.active ? "Active" : "Inactive"}
											</span>
											{employer.verified && (
												<span className="inline-flex px-2 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
													Verified
												</span>
											)}
										</div>
									</td>
									<td className="px-6 py-4 text-right">
										<button
											onClick={() => handleEdit(employer)}
											className="text-blue-600 hover:text-blue-900"
										>
											Edit
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{/* Pagination */}
			<div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
				<div className="flex justify-between items-center w-full">
					<button
						onClick={() => setPage((p) => Math.max(1, p - 1))}
						disabled={page === 1}
						className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
					>
						Previous
					</button>
					<div className="text-sm text-gray-700">
						Page {page} of {totalPages}
					</div>
					<button
						onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
						disabled={page === totalPages}
						className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
					>
						Next
					</button>
				</div>
			</div>
		</div>
	);
};

export default EmployersTable;
