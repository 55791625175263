import React, { useState, useEffect } from "react";
import InstitutionDialog from "../../components/atsflow/InstitutionDialog";
import InstitutionsTable from "../../components/atsflow/InstitutionsTable";

const PROVINCES = [
	"ON",
	"BC",
	"AB",
	"SK",
	"MB",
	"QC",
	"NB",
	"NS",
	"PE",
	"NL",
	"YT",
	"NT",
	"NU",
];

const Institutions = () => {
	const [institutions, setInstitutions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentInstitution, setCurrentInstitution] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [alert, setAlert] = useState({ type: "", message: "" });
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const fetchInstitutions = async () => {
		try {
			const response = await fetch("/ats-institutions/");
			if (!response.ok) throw new Error("Failed to fetch institutions");
			const data = await response.json();
			setInstitutions(data);
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to load institutions. Please try again.",
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchInstitutions();
	}, []);

	const validateInstitutionData = (data) => {
		const errors = [];
		const required = [
			"name",
			"street",
			"city",
			"province",
			"postal_code",
			"primary_contact_name",
			"primary_contact_phone",
			"primary_contact_email",
		];

		required.forEach((field) => {
			if (!data[field]) {
				errors.push(`${field.replace(/_/g, " ")} is required`);
			}
		});

		// Email validation
		const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		if (
			data.primary_contact_email &&
			!emailRegex.test(data.primary_contact_email)
		) {
			errors.push("Invalid email format");
		}

		// Phone validation
		const phoneRegex = /^\d{10}$/;
		if (
			data.primary_contact_phone &&
			!phoneRegex.test(data.primary_contact_phone.replace(/\D/g, ""))
		) {
			errors.push("Phone number must be 10 digits");
		}

		// Postal code validation
		const postalRegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
		if (data.postal_code && !postalRegex.test(data.postal_code)) {
			errors.push("Invalid postal code format (e.g., A1A 1A1)");
		}

		return errors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const institutionData = Object.fromEntries(formData.entries());

		// Handle boolean values
		institutionData.active = formData.get("active") === "on";
		institutionData.verified = formData.get("verified") === "on";

		const errors = validateInstitutionData(institutionData);
		if (errors.length > 0) {
			setAlert({
				type: "error",
				message: `Validation errors: ${errors.join(", ")}`,
			});
			return;
		}

		try {
			const response = await fetch(
				`/ats-institutions/${currentInstitution?.id || ""}`,
				{
					method: isEditing ? "PUT" : "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(institutionData),
				},
			);

			if (!response.ok) throw new Error("Failed to save institution");

			setAlert({
				type: "success",
				message: `Institution successfully ${
					isEditing ? "updated" : "created"
				}`,
			});
			setIsModalOpen(false);
			fetchInstitutions();
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to save institution. Please try again.",
			});
		}
	};

	return (
		<div className="space-y-6">
			{/* Alert Message */}
			{alert.type && (
				<div
					className={`p-4 mb-4 rounded ${
						alert.type === "success"
							? "bg-green-50 text-green-800"
							: "bg-red-50 text-red-800"
					}`}
				>
					{alert.message}
				</div>
			)}

			{/* Header */}
			<div className="flex justify-between items-center">
				<h1 className="text-2xl font-bold">Training Institutions</h1>
				<button
					onClick={() => {
						setIsEditing(false);
						setCurrentInstitution(null);
						setIsModalOpen(true);
					}}
					className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
				>
					Add New Institution
				</button>
			</div>

			{/* Content */}
			{isLoading ? (
				<div className="flex items-center justify-center p-8">
					<div className="flex items-center space-x-2">
						<i className="bi bi-arrow-clockwise animate-spin text-blue-600"></i>
						<span>Loading Institutions...</span>
					</div>
				</div>
			) : (
				<InstitutionsTable
					institutions={institutions}
					handleEdit={(institution) => {
						setCurrentInstitution(institution);
						setIsEditing(true);
						setIsModalOpen(true);
					}}
					page={page}
					totalPages={totalPages}
					setPage={setPage}
				/>
			)}

			{/* Modal */}
			<InstitutionDialog
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				institution={currentInstitution}
				isEditing={isEditing}
				handleSubmit={handleSubmit}
				PROVINCES={PROVINCES}
			/>
		</div>
	);
};

export default Institutions;
