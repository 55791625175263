import MakerBlocks from "./MakerBlocks";
import WordPressLocalize from "./WordPressLocalize";
import CollapsePanel from "./CollapsePanel";
import ModalNoScroll from "./ModalNoScroll";
import ContactTabs from "./ContactTabs";
import ComponentRenderer from "./ComponentRenderer";

// Initialize main scripts
new WordPressLocalize();
new MakerBlocks();
new CollapsePanel();
new ModalNoScroll();
new ComponentRenderer();
