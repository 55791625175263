import React, { useState, useEffect } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import ApplicationForm from "./ApplicationForm";

const ApplicationDialog = ({
	isOpen,
	onClose,
	selectedEntry,
	detailedEntry,
	isLoadingDetails,
	handleEntryUpdate,
	isSaving,
	FIELD_MAPPINGS,
	formatDate,
}) => {
	const [formData, setFormData] = useState(selectedEntry || {});
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [saveStatus, setSaveStatus] = useState({ type: null, message: null });

	useEffect(() => {
		if (detailedEntry) {
			setFormData(detailedEntry);
		}
	}, [detailedEntry]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[FIELD_MAPPINGS[name] || name]: value,
		}));
		setIsEditing(true);
		setHasUnsavedChanges(true);
	};

	const handleSave = async (e) => {
		e.preventDefault();
		if (!hasUnsavedChanges || isSaving) return;

		try {
			await handleEntryUpdate(formData);
			setHasUnsavedChanges(false);
			setSaveStatus({
				type: "success",
				message: "Application updated successfully",
			});
			setIsEditing(false);
		} catch (error) {
			setSaveStatus({
				type: "error",
				message:
					error.message || "Failed to update application. Please try again.",
			});
		}
	};

	const handleClose = () => {
		if (hasUnsavedChanges && isEditing) {
			if (
				window.confirm(
					"You have unsaved changes. Are you sure you want to close?",
				)
			) {
				closeModal();
			}
		} else {
			closeModal();
		}
	};

	const closeModal = () => {
		onClose();
		setIsEditing(false);
		setSaveStatus({ type: null, message: null });
	};

	return (
		<Dialog open={isOpen} onClose={handleClose} className="relative z-50">
			<div className="fixed inset-0 bg-black/30" aria-hidden="true" />

			<div className="fixed inset-0 flex items-center justify-center p-4">
				<DialogPanel className="mx-auto max-w-4xl rounded bg-white p-6 w-full max-h-[90vh] overflow-y-auto">
					{isLoadingDetails ? (
						<div className="flex items-center justify-center p-8">
							<div className="flex flex-col items-center gap-2">
								<i className="bi bi-arrow-clockwise animate-spin text-blue-600 text-2xl"></i>
								<span className="text-sm text-gray-600">
									Loading application details...
								</span>
							</div>
						</div>
					) : (
						<>
							<div className="flex justify-between items-center mb-6">
								<DialogTitle className="text-xl font-medium">
									{selectedEntry ? "Edit Application" : "New Application"}
								</DialogTitle>
								<button
									onClick={handleClose}
									className="text-gray-400 hover:text-gray-500"
								>
									<span className="sr-only">Close</span>
									<svg
										className="h-6 w-6"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M6 18L18 6M6 6l12 12"
										/>
									</svg>
								</button>
							</div>

							{saveStatus.type && (
								<div
									className={`p-4 mb-4 rounded ${
										saveStatus.type === "success"
											? "bg-green-50 text-green-800"
											: "bg-red-50 text-red-800"
									}`}
								>
									{saveStatus.message}
								</div>
							)}

							<ApplicationForm
								formData={formData}
								handleInputChange={handleInputChange}
								handleSave={handleSave}
								isEditing={isEditing}
								setIsEditing={setIsEditing}
								isSaving={isSaving}
								hasUnsavedChanges={hasUnsavedChanges}
								setFormData={setFormData}
								detailedEntry={detailedEntry}
								setHasUnsavedChanges={setHasUnsavedChanges}
								FIELD_MAPPINGS={FIELD_MAPPINGS}
								formatDate={formatDate}
							/>
						</>
					)}
				</DialogPanel>
			</div>
		</Dialog>
	);
};

export default ApplicationDialog;
