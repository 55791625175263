import React, { useState, useEffect } from "react";
import PlacementDialog from "../../components/atsflow/PlacementDialog";
import PlacementsTable from "../../components/atsflow/PlacementsTable";

const Placements = () => {
	const [placements, setPlacements] = useState([]);
	const [applicants, setApplicants] = useState([]);
	const [jobs, setJobs] = useState([]);
	const [employers, setEmployers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentPlacement, setCurrentPlacement] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [alert, setAlert] = useState({ type: "", message: "" });
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const [placementsRes, applicantsRes, jobsRes, employersRes] =
				await Promise.all([
					fetch("/ats-placements/"),
					fetch("/ats-applicants/"),
					fetch("/ats-jobs/"),
					fetch("/ats-employers/"),
				]);

			if (!placementsRes.ok) throw new Error("Failed to fetch placements");
			if (!applicantsRes.ok) throw new Error("Failed to fetch applicants");
			if (!jobsRes.ok) throw new Error("Failed to fetch jobs");
			if (!employersRes.ok) throw new Error("Failed to fetch employers");

			const [placementsData, applicantsData, jobsData, employersData] =
				await Promise.all([
					placementsRes.json(),
					applicantsRes.json(),
					jobsRes.json(),
					employersRes.json(),
				]);

			setPlacements(placementsData);
			setApplicants(applicantsData);
			setJobs(jobsData);
			setEmployers(employersData);
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to load data. Please try again.",
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const validatePlacementData = (data) => {
		const errors = [];
		const required = ["applicant_id", "job_id", "hired_date"];

		required.forEach((field) => {
			if (!data[field]) {
				errors.push(`${field.replace(/_/g, " ")} is required`);
			}
		});

		// Validate hired date is not in future
		if (data.hired_date) {
			const hiredDate = new Date(data.hired_date);
			if (hiredDate > new Date()) {
				errors.push("Hired date cannot be in the future");
			}
		}

		// Additional validation can be added here

		return errors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const placementData = Object.fromEntries(formData.entries());

		// Handle boolean value
		placementData.active = formData.get("active") === "on";

		const errors = validatePlacementData(placementData);
		if (errors.length > 0) {
			setAlert({
				type: "error",
				message: `Validation errors: ${errors.join(", ")}`,
			});
			return;
		}

		try {
			const response = await fetch(
				`/ats-placements/${currentPlacement?.id || ""}`,
				{
					method: isEditing ? "PUT" : "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(placementData),
				},
			);

			if (!response.ok) throw new Error("Failed to save placement");

			setAlert({
				type: "success",
				message: `Placement successfully ${isEditing ? "updated" : "created"}`,
			});
			setIsModalOpen(false);
			fetchData();
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to save placement. Please try again.",
			});
		}
	};

	const getPlacementDetails = (placement) => {
		const applicant = applicants.find((a) => a.id === placement.applicant_id);
		const job = jobs.find((j) => j.id === placement.job_id);
		const employer = job
			? employers.find((e) => e.id === job.employer_id)
			: null;

		return {
			...placement,
			applicant,
			job,
			employer,
		};
	};

	return (
		<div className="space-y-6">
			{/* Alert Message */}
			{alert.type && (
				<div
					className={`p-4 mb-4 rounded ${
						alert.type === "success"
							? "bg-green-50 text-green-800"
							: "bg-red-50 text-red-800"
					}`}
				>
					{alert.message}
				</div>
			)}

			{/* Header */}
			<div className="flex justify-between items-center">
				<h1 className="text-2xl font-bold">Placements</h1>
				<button
					onClick={() => {
						setIsEditing(false);
						setCurrentPlacement(null);
						setIsModalOpen(true);
					}}
					className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
				>
					Add New Placement
				</button>
			</div>

			{/* Content */}
			{isLoading ? (
				<div className="flex items-center justify-center p-8">
					<div className="flex items-center space-x-2">
						<i className="bi bi-arrow-clockwise animate-spin text-blue-600"></i>
						<span>Loading Placements...</span>
					</div>
				</div>
			) : (
				<PlacementsTable
					placements={placements.map(getPlacementDetails)}
					handleEdit={(placement) => {
						setCurrentPlacement(placement);
						setIsEditing(true);
						setIsModalOpen(true);
					}}
					page={page}
					totalPages={totalPages}
					setPage={setPage}
				/>
			)}

			{/* Modal */}
			<PlacementDialog
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				placement={currentPlacement}
				applicants={applicants}
				jobs={jobs}
				employers={employers}
				isEditing={isEditing}
				handleSubmit={handleSubmit}
			/>
		</div>
	);
};

export default Placements;
