import ReactDOM from "react-dom/client";
// Components
import ATSFlow from "./apps/ATSFlow";
import ButtonRegister from "./components/ButtonRegister";
import Button from "./components/Button";
import ContactCard from "./components/ContactCard";
import SearchForm from "./components/SearchForm";
import SocialIcons from "./components/SocialIcons";

class ComponentRenderer {
	constructor() {
		// Start rendering components when DOM is fully loaded
		document.addEventListener("DOMContentLoaded", () => {
			this.renderComponents();
		});
	}

	renderComponents() {
		try {
			// Render all components by calling this.renderComponent
			this.renderComponent(".button-about-us", Button, (element) => ({
				icon: "info-circle-fill",
				postId: 7,
				buttonText: "Learn More About Us",
				buttonType: "primary",
			}));

			this.renderComponent(".button-view-courses", Button, (element) => ({
				icon: "mortarboard-fill",
				postId: 155,
				buttonText: "View All Courses",
				buttonType: "secondary",
			}));

			this.renderComponent(".button-register", ButtonRegister, (element) => ({
				href: "#modal-register",
				buttonText: "Apply Now!",
			}));

			this.renderComponent(".contact-card", ContactCard);

			this.renderComponent(".search-form", SearchForm);

			this.renderComponent(".social-contact-loop", SocialIcons);

			this.renderComponent("#atsflow-app", ATSFlow)
		} catch (error) {
			// Catch and log any rendering errors
			console.error(
				"An error occurred while rendering the React components:",
				error,
			);
		}
	}

	/**
	 * A utility method to render a React component inside a selected DOM element.
	 * @param {string} selector - The CSS selector to find the elements to render the component into.
	 * @param {React.Component} Component - The React component to render.
	 * @param {Function} getProps - A function to extract props from the DOM element (defaults to parsing data-props).
	 */
	renderComponent(
		selector,
		Component,
		getProps = (element) => {
			const propsString = element.getAttribute("data-props");
			if (propsString) {
				try {
					return JSON.parse(propsString);
				} catch (error) {
					console.error("Error parsing props:", error);
				}
			}
			return {};
		},
	) {
		const elements = document.querySelectorAll(selector);
		if (elements.length > 0) {
			console.log(`Found ${elements.length} ${selector} elements.`);
			elements.forEach((element, index) => {
				// Check if the component has already been rendered
				if (!element.hasAttribute("data-rendered")) {
					const props = getProps(element, index);
					console.log(
						`Rendering ${Component.name} component in element ${
							index + 1
						} with props:`,
						props,
					);
					ReactDOM.createRoot(element).render(<Component {...props} />);
					// Mark the element as rendered
					element.setAttribute("data-rendered", "true");
				} else {
					console.log(
						`Component already rendered in element ${index + 1}. Skipping...`,
					);
				}
			});
			console.log(`All ${Component.name} components rendered successfully.`);
		} else {
			console.warn(`No elements with the selector '${selector}' found.`);
		}
	}
}

export default ComponentRenderer;
