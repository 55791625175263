import React from "react";
import { Dialog } from "@headlessui/react";

const EmployerDialog = ({
	isOpen,
	onClose,
	employer,
	isEditing,
	handleSubmit,
	COMPANY_SIZES,
	PROVINCES,
}) => {
	return (
		<Dialog open={isOpen} onClose={onClose} className="relative z-50">
			<div className="fixed inset-0 bg-black/30" aria-hidden="true" />

			<div className="fixed inset-0 flex items-center justify-center p-4">
				<Dialog.Panel className="mx-auto max-w-4xl w-full rounded bg-white p-6 shadow-xl max-h-[90vh] overflow-y-auto">
					<div className="flex justify-between items-center mb-6">
						<Dialog.Title className="text-xl font-medium">
							{isEditing ? "Edit Employer" : "Add New Employer"}
						</Dialog.Title>
						<button
							onClick={onClose}
							className="text-gray-400 hover:text-gray-500"
						>
							<span className="sr-only">Close</span>
							<svg
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>

					<form onSubmit={handleSubmit} className="space-y-6">
						{/* Business Information */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Business Information</h3>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
								<div className="col-span-2">
									<label className="block text-sm font-medium text-gray-700">
										Business Name
									</label>
									<input
										type="text"
										name="business_name"
										defaultValue={employer?.business_name || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									/>
								</div>
								<div className="col-span-2">
									<label className="block text-sm font-medium text-gray-700">
										Legal Name
									</label>
									<input
										type="text"
										name="legal_name"
										defaultValue={employer?.legal_name || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Business Number
									</label>
									<input
										type="text"
										name="business_number"
										defaultValue={employer?.business_number || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Industry Sector
									</label>
									<input
										type="text"
										name="industry_sector"
										defaultValue={employer?.industry_sector || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Company Size
									</label>
									<select
										name="company_size"
										defaultValue={employer?.company_size || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									>
										<option value="">Select Size</option>
										{COMPANY_SIZES.map((size) => (
											<option key={size} value={size}>
												{size}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>

						{/* Address Information */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Address Information</h3>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
								<div className="col-span-2">
									<label className="block text-sm font-medium text-gray-700">
										Street Address
									</label>
									<input
										type="text"
										name="street"
										defaultValue={employer?.street || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										City
									</label>
									<input
										type="text"
										name="city"
										defaultValue={employer?.city || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Province
									</label>
									<select
										name="province"
										defaultValue={employer?.province || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									>
										<option value="">Select Province</option>
										{PROVINCES.map((province) => (
											<option key={province} value={province}>
												{province}
											</option>
										))}
									</select>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Postal Code
									</label>
									<input
										type="text"
										name="postal_code"
										defaultValue={employer?.postal_code || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										pattern="[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]"
										required
									/>
								</div>
							</div>
						</div>

						{/* Primary Contact */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Primary Contact</h3>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
								<div className="col-span-2">
									<label className="block text-sm font-medium text-gray-700">
										Contact Name
									</label>
									<input
										type="text"
										name="primary_contact_name"
										defaultValue={employer?.primary_contact_name || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Title
									</label>
									<input
										type="text"
										name="primary_contact_title"
										defaultValue={employer?.primary_contact_title || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Phone
									</label>
									<input
										type="tel"
										name="primary_contact_phone"
										defaultValue={employer?.primary_contact_phone || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									/>
								</div>
								<div className="col-span-2">
									<label className="block text-sm font-medium text-gray-700">
										Email
									</label>
									<input
										type="email"
										name="primary_contact_email"
										defaultValue={employer?.primary_contact_email || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									/>
								</div>
							</div>
						</div>

						{/* Additional Information */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Additional Information</h3>
							<div className="grid grid-cols-1 gap-4">
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Notes
									</label>
									<textarea
										name="notes"
										defaultValue={employer?.notes || ""}
										rows="3"
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									/>
								</div>
								<div className="flex space-x-4">
									<div className="flex items-center">
										<input
											type="checkbox"
											name="active"
											defaultChecked={employer?.active || true}
											className="h-4 w-4 text-blue-600 rounded border-gray-300"
										/>
										<label className="ml-2 text-sm text-gray-700">Active</label>
									</div>
									<div className="flex items-center">
										<input
											type="checkbox"
											name="verified"
											defaultChecked={employer?.verified || false}
											className="h-4 w-4 text-blue-600 rounded border-gray-300"
										/>
										<label className="ml-2 text-sm text-gray-700">
											Verified
										</label>
									</div>
								</div>
							</div>
						</div>

						{/* Form Actions */}
						<div className="flex justify-end space-x-3 pt-4 border-t">
							<button
								type="button"
								onClick={onClose}
								className="px-4 py-2 text-gray-700 bg-gray-100 rounded hover:bg-gray-200"
							>
								Cancel
							</button>
							<button
								type="submit"
								className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
							>
								{isEditing ? "Update" : "Create"} Employer
							</button>
						</div>
					</form>
				</Dialog.Panel>
			</div>
		</Dialog>
	);
};

export default EmployerDialog;
