import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";

const EnrollmentDialog = ({
	isOpen,
	onClose,
	enrollment,
	applicants,
	courses,
	institutions,
	isEditing,
	handleSubmit,
	SCORE_TYPES,
}) => {
	const [selectedInstitutionId, setSelectedInstitutionId] = useState("");
	const [selectedCourseId, setSelectedCourseId] = useState("");

	// Reset states when modal opens/closes
	useEffect(() => {
		if (isOpen && enrollment?.course) {
			// If editing, set initial values
			setSelectedCourseId(enrollment.course_id);
			setSelectedInstitutionId(enrollment.course.institution_id);
		} else if (!isOpen) {
			// Clear values when closing
			setSelectedCourseId("");
			setSelectedInstitutionId("");
		}
	}, [isOpen, enrollment]);

	// Get available courses based on institution selection
	const availableCourses =
		selectedInstitutionId === ""
			? courses // Show all courses if no institution selected
			: courses.filter(
					(course) => course.institution_id === parseInt(selectedInstitutionId),
			  );

	const handleInstitutionChange = (e) => {
		const newInstitutionId = e.target.value;
		setSelectedInstitutionId(newInstitutionId);
		// Always clear course when institution changes
		setSelectedCourseId("");
	};

	const handleCourseChange = (e) => {
		const newCourseId = e.target.value;
		setSelectedCourseId(newCourseId);

		// If a course is selected, update institution to match
		if (newCourseId) {
			const course = courses.find((c) => c.id === parseInt(newCourseId));
			if (course) {
				setSelectedInstitutionId(course.institution_id.toString());
			}
		}
	};

	// Return just the course selection part for testing
	return (
		<Dialog open={isOpen} onClose={onClose} className="relative z-50">
			<div className="fixed inset-0 bg-black/30" aria-hidden="true" />
			<div className="fixed inset-0 flex items-center justify-center p-4">
				<Dialog.Panel className="mx-auto max-w-4xl w-full rounded bg-white p-6 shadow-xl max-h-[90vh] overflow-y-auto">
					<div className="flex justify-between items-center mb-6">
						<Dialog.Title className="text-xl font-medium">
							{isEditing ? "Edit Enrollment" : "Add New Enrollment"}
						</Dialog.Title>
						<button
							onClick={onClose}
							className="text-gray-400 hover:text-gray-500"
						>
							<span className="sr-only">Close</span>
							<svg
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>

					<form onSubmit={handleSubmit} className="space-y-6">
						{/* Student Selection */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Student Information</h3>
							<div>
								<label className="block text-sm font-medium text-gray-700">
									Select Student
								</label>
								<select
									name="applicant_id"
									defaultValue={enrollment?.applicant_id || ""}
									className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									required
								>
									<option value="">Select Student</option>
									{applicants.map((applicant) => (
										<option key={applicant.id} value={applicant.id}>
											{applicant.firstname} {applicant.lastname} -{" "}
											{applicant.email}
										</option>
									))}
								</select>
							</div>
						</div>

					{/* Course Selection Section */}
					<div className="bg-gray-50 p-4 rounded-lg">
						<h3 className="font-medium mb-4">Course Information</h3>
						<div className="grid grid-cols-1 gap-4">
							<div>
								<label className="block text-sm font-medium text-gray-700">
									Select Institution
								</label>
								<select
									value={selectedInstitutionId}
									onChange={handleInstitutionChange}
									className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								>
									<option value="">All Institutions</option>
									{institutions.map((institution) => (
										<option
											key={institution.id}
											value={institution.id.toString()}
										>
											{institution.name} - {institution.city}
										</option>
									))}
								</select>
							</div>
							<div>
								<label className="block text-sm font-medium text-gray-700">
									Select Course
								</label>
								<select
									name="course_id"
									value={selectedCourseId}
									onChange={handleCourseChange}
									className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									required
								>
									<option value="">Select Course</option>
									{availableCourses.map((course) => (
										<option key={course.id} value={course.id.toString()}>
											{course.name} - {course.type}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>

					{/* Enrollment Details */}
						<div className="bg-gray-50 p-4 rounded-lg">
							<h3 className="font-medium mb-4">Enrollment Details</h3>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Enrollment Date
									</label>
									<input
										type="date"
										name="enrollment_date"
										defaultValue={
											enrollment?.enrollment_date?.split(" ")[0] ||
											new Date().toISOString().split("T")[0]
										}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										required
									/>
								</div>
								<div>
									<label className="block text-sm font-medium text-gray-700">
										Score
									</label>
									<select
										name="score"
										defaultValue={enrollment?.score || ""}
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
									>
										<option value="">Select Score</option>
										{SCORE_TYPES.map((type) => (
											<option key={type.value} value={type.value}>
												{type.label}
											</option>
										))}
									</select>
								</div>
								<div className="col-span-2">
									<label className="block text-sm font-medium text-gray-700">
										Assessment Notes
									</label>
									<textarea
										name="assessment"
										defaultValue={enrollment?.assessment || ""}
										rows="3"
										className="mt-1 block w-full rounded border-gray-300 shadow-sm"
										placeholder="Enter assessment notes..."
									/>
								</div>
							</div>
						</div>

						{/* Enrollment History */}
						{isEditing && (
							<div className="bg-gray-50 p-4 rounded-lg">
								<h3 className="font-medium mb-4">Enrollment History</h3>
								<div className="grid grid-cols-2 gap-4 text-sm">
									<div>
										<span className="font-medium">Created:</span>
										<div className="text-gray-600">
											{new Date(enrollment.created_at).toLocaleString()}
										</div>
									</div>
									<div>
										<span className="font-medium">Last Updated:</span>
										<div className="text-gray-600">
											{new Date(enrollment.updated_at).toLocaleString()}
										</div>
									</div>
								</div>
							</div>
						)}

						{/* Form Actions */}
						<div className="flex justify-end space-x-3 pt-4 border-t">
							<button
								type="button"
								onClick={onClose}
								className="px-4 py-2 text-gray-700 bg-gray-100 rounded hover:bg-gray-200"
							>
								Cancel
							</button>
							<button
								type="submit"
								className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
							>
								{isEditing ? "Update" : "Create"} Enrollment
							</button>
						</div>
					</form>
				</Dialog.Panel>
			</div>
		</Dialog>
	);
};

export default EnrollmentDialog;