import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import DashboardLayout from "../layouts/atsflow/DashboardLayout";
import Dashboard from "../pages/atsflow/Dashboard";
import Applications from "../pages/atsflow/Applications";
import Applicants from "../pages/atsflow/Applicants";
import Courses from "../pages/atsflow/Courses";
import Employers from "../pages/atsflow/Employers";
import Jobs from "../pages/atsflow/Jobs";
import Database from "../pages/atsflow/Database";
import Reports from "../pages/atsflow/Reports";
import Placements from "../pages/atsflow/Placements";
import Enrollments from "../pages/atsflow/Enrollments";
import InfoSessions from "../pages/atsflow/InfoSessions";
import SessionInvites from "../pages/atsflow/SessionInvites";
import Services from "../pages/atsflow/Services";
import Expenses from "../pages/atsflow/Expenses";
import Documents from "../pages/atsflow/Documents";
import Statuses from "../pages/atsflow/Statuses";
import Institutions from "../pages/atsflow/Institutions";

export default function ATSFlow() {
	return (
		<Router basename="/applicant-tracking-system">
			<Routes>
				<Route path="/" element={<Navigate to="/dashboard" replace />} />
				<Route element={<DashboardLayout />}>
					<Route path="/dashboard" element={<Dashboard />} />
					<Route path="/applications" element={<Applications />} />
					<Route path="/applicants" element={<Applicants />} />
					<Route path="/institutions" element={<Institutions />} />
					<Route path="/courses" element={<Courses />} />
					<Route path="/enrollments" element={<Enrollments />} />
					<Route path="/employers" element={<Employers />} />
					<Route path="/jobs" element={<Jobs />} />
					<Route path="/placements" element={<Placements />} />
					<Route path="/info-sessions" element={<InfoSessions />} />
					<Route path="/session-invites" element={<SessionInvites />} />
					<Route path="/services" element={<Services />} />
					<Route path="/expenses" element={<Expenses />} />
					<Route path="/documents" element={<Documents />} />
					<Route path="/statuses" element={<Statuses />} />
					<Route path="/database" element={<Database />} />
					<Route path="/reports" element={<Reports />} />
				</Route>
			</Routes>
		</Router>
	);
}
