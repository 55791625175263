import React, { useState, useEffect } from "react";
import ApplicationDialog from "../../components/atsflow/ApplicationDialog";
import ApplicationsTable from "../../components/atsflow/ApplicationsTable";

const FIELD_MAPPINGS = {
	firstName: "3.3",
	lastName: "3.6",
	phone: "4",
	email: "5",
	city: "12.3",
	province: "12.4",
	constructionTrade: "14.1",
	electrician: "14.2",
	plumbing: "14.3",
	forklift: "14.4",
	welding: "14.5",
	safetyCourses: "14.6",
	homeRenovation: "17.1",
	culinary: "17.2",
	foodHandling: "17.3",
	bartending: "17.4",
	housekeeping: "17.5",
	leadership: "17.6",
	otherInterests: "16",
	volunteer: "21",
	dateCreated: "date_created",
	status: "status",
};

const Applications = () => {
	const [entries, setEntries] = useState([]);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [sortConfig, setSortConfig] = useState({
		key: "date_created",
		direction: "desc",
	});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedEntry, setSelectedEntry] = useState(null);
	const [detailedEntry, setDetailedEntry] = useState(null);
	const [isLoadingDetails, setIsLoadingDetails] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [alert, setAlert] = useState({ type: "", message: "" });

	const fetchEntries = async (currentPage) => {
		setIsLoading(true);
		try {
			const response = await fetch(
				`/wp-json/gf/v2/forms/4/entries?_labels=1&sorting[direction]=DESC&paging[page_size]=1000&paging[current_page]=${currentPage}`,
			);

			if (!response.ok) throw new Error(response.status.toString());

			const data = await response.json();
			setEntries(data.entries);
			setTotalPages(Math.ceil(data.total_count / 1000));
		} catch (error) {
			console.error("Error fetching data:", error);
			setAlert({
				type: "error",
				message: "Failed to load applications. Please try again.",
			});
			if (error.message === "401") {
				setAlert({
					type: "error",
					message: "Unauthorized access. Please log in to view the data.",
				});
			}
		} finally {
			setIsLoading(false);
		}
	};

	const fetchEntryDetails = async (entryId) => {
		setIsLoadingDetails(true);
		try {
			const response = await fetch(
				`/wp-json/gf/v2/entries/${entryId}?_labels=1`,
			);

			if (!response.ok) throw new Error(response.status.toString());

			const data = await response.json();
			setDetailedEntry(data);
		} catch (error) {
			console.error("Error fetching entry details:", error);
			setAlert({
				type: "error",
				message: "Failed to load entry details. Please try again.",
			});
		} finally {
			setIsLoadingDetails(false);
		}
	};

	useEffect(() => {
		fetchEntries(page);
	}, [page]);

	const sortedEntries = React.useMemo(() => {
		return [...entries].sort((a, b) => {
			const aVal = a[sortConfig.key];
			const bVal = b[sortConfig.key];
			return sortConfig.direction === "asc"
				? aVal > bVal
					? 1
					: -1
				: aVal < bVal
				? 1
				: -1;
		});
	}, [entries, sortConfig]);

	const handleSort = (key) => {
		setSortConfig((prev) => ({
			key,
			direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
		}));
	};

	const handleEntryUpdate = async (updatedEntry) => {
		setIsSaving(true);
		try {
			const response = await fetch(
				`/wp-json/gf/v2/entries/${selectedEntry.id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(updatedEntry),
				},
			);

			if (!response.ok) {
				const errorData = await response.json().catch(() => null);
				throw new Error(errorData?.message || "Update failed");
			}

			const updatedResponse = await fetch(
				`/wp-json/gf/v2/entries/${selectedEntry.id}?_labels=1`,
			);

			if (!updatedResponse.ok) {
				throw new Error("Failed to fetch updated entry");
			}

			const updatedEntryData = await updatedResponse.json();
			setDetailedEntry(updatedEntryData);
			setAlert({
				type: "success",
				message: "Application updated successfully",
			});
			await fetchEntries(page);
		} catch (error) {
			console.error("Error updating entry:", error);
			setAlert({
				type: "error",
				message:
					error.message || "Failed to update application. Please try again.",
			});
		} finally {
			setIsSaving(false);
		}
	};

	const formatDate = (dateString) => {
		return new Date(dateString).toLocaleString();
	};

	const handleOpenModal = (entry) => {
		setSelectedEntry(entry);
		setIsModalOpen(true);
		fetchEntryDetails(entry.id);
	};

	return (
		<div className="space-y-6">
			{/* Alert Message */}
			{alert.type && (
				<div
					className={`p-4 mb-4 rounded ${
						alert.type === "success"
							? "bg-green-50 text-green-800"
							: "bg-red-50 text-red-800"
					}`}
				>
					{alert.message}
				</div>
			)}

			{/* Header */}
			<div className="flex justify-between items-center">
				<h1 className="text-2xl font-bold">Applications</h1>
				<button
					onClick={() => {
						setSelectedEntry(null);
						setDetailedEntry(null);
						setIsModalOpen(true);
					}}
					className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
				>
					Add New Application
				</button>
			</div>

			{/* Content */}
			{isLoading ? (
				<div className="flex items-center justify-center p-8">
					<div className="flex items-center space-x-2">
						<i className="bi bi-arrow-clockwise animate-spin text-blue-600"></i>
						<span>Loading Applications...</span>
					</div>
				</div>
			) : (
				<ApplicationsTable
					sortedEntries={sortedEntries}
					handleSort={handleSort}
					handleOpenModal={handleOpenModal}
					FIELD_MAPPINGS={FIELD_MAPPINGS}
					formatDate={formatDate}
					page={page}
					totalPages={totalPages}
					setPage={setPage}
					entries={entries}
				/>
			)}

			{/* Modal */}
			<ApplicationDialog
				isOpen={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
					setSelectedEntry(null);
					setDetailedEntry(null);
				}}
				selectedEntry={selectedEntry}
				detailedEntry={detailedEntry}
				isLoadingDetails={isLoadingDetails}
				handleEntryUpdate={handleEntryUpdate}
				isSaving={isSaving}
				FIELD_MAPPINGS={FIELD_MAPPINGS}
				formatDate={formatDate}
			/>
		</div>
	);
};

export default Applications;
