import React, { useState, useEffect } from "react";

const Courses = () => {
	const [courses, setCourses] = useState([]);
	const [institutions, setInstitutions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentCourse, setCurrentCourse] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [alert, setAlert] = useState({ type: "", message: "" });
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const COURSE_TYPES = [
		{ value: "construction", label: "Construction" },
		{ value: "hospitality", label: "Hospitality" },
	];

	const PROVINCES = [
		"ON",
		"BC",
		"AB",
		"SK",
		"MB",
		"QC",
		"NB",
		"NS",
		"PE",
		"NL",
		"YT",
		"NT",
		"NU",
	];

	const fetchInstitutions = async () => {
		try {
			const response = await fetch("/ats-institutions/");
			if (!response.ok) throw new Error("Failed to fetch institutions");
			const data = await response.json();
			setInstitutions(data);
		} catch (error) {
			console.error("Failed to load institutions:", error);
		}
	};

	const fetchCourses = async () => {
		try {
			const response = await fetch("/ats-courses/");
			if (!response.ok) throw new Error("Failed to fetch courses");
			const data = await response.json();
			setCourses(data);
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to load courses. Please try again.",
			});
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		Promise.all([fetchCourses(), fetchInstitutions()]).then(() => {
			setIsLoading(false);
		});
	}, []);

	const validateCourseData = (data) => {
		const errors = [];
		const required = [
			"name",
			"type",
			"institution_id",
			"description",
			"location",
			"street",
			"city",
			"province",
			"postal_code",
			"start_date",
			"end_date",
		];

		required.forEach((field) => {
			if (!data[field]) errors.push(`${field} is required`);
		});

		if (data.start_date && data.end_date) {
			const start = new Date(data.start_date);
			const end = new Date(data.end_date);
			if (end < start) errors.push("End date must be after start date");
		}

		if (data.max_seats && Number(data.max_seats) < 1) {
			errors.push("Maximum seats must be greater than 0");
		}

		return errors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const courseData = Object.fromEntries(formData.entries());

		if (!isEditing) {
			courseData.current_seats = 0;
			courseData.created_at = new Date().toISOString();
		}
		courseData.updated_at = new Date().toISOString();

		const errors = validateCourseData(courseData);
		if (errors.length > 0) {
			setAlert({
				type: "error",
				message: `Validation errors: ${errors.join(", ")}`,
			});
			return;
		}

		try {
			const response = await fetch(`/ats-courses/${currentCourse?.id || ""}`, {
				method: isEditing ? "PUT" : "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(courseData),
			});

			if (!response.ok) throw new Error("Failed to save course");

			setAlert({
				type: "success",
				message: `Course successfully ${isEditing ? "updated" : "created"}`,
			});
			setIsModalOpen(false);
			fetchCourses();
		} catch (error) {
			setAlert({
				type: "error",
				message: "Failed to save course. Please try again.",
			});
		}
	};

	const getStatusBadgeClass = (course) => {
		const now = new Date();
		const startDate = new Date(course.start_date);
		const endDate = new Date(course.end_date);

		if (!course.active) return "bg-gray-100 text-gray-800";
		if (now < startDate) return "bg-yellow-100 text-yellow-800";
		if (now > endDate) return "bg-red-100 text-red-800";
		return "bg-green-100 text-green-800";
	};

	const getStatusText = (course) => {
		const now = new Date();
		const startDate = new Date(course.start_date);
		const endDate = new Date(course.end_date);

		if (!course.active) return "Inactive";
		if (now < startDate) return "Upcoming";
		if (now > endDate) return "Completed";
		return "In Progress";
	};

	const Modal = ({ isOpen, onClose, title, children }) => {
		if (!isOpen) return null;

		return (
			<div className="fixed inset-0 z-50 overflow-y-auto">
				<div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center">
					<div className="fixed inset-0 transition-opacity" aria-hidden="true">
						<div className="absolute inset-0 bg-gray-500 opacity-75"></div>
					</div>

					<div className="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
						<div className="flex justify-between items-center mb-4">
							<h3 className="text-lg font-medium text-gray-900">{title}</h3>
							<button
								onClick={onClose}
								className="text-gray-400 hover:text-gray-500"
							>
								<span className="sr-only">Close</span>
								<svg
									className="h-6 w-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
						<div className="mt-4">{children}</div>
					</div>
				</div>
			</div>
		);
	};

	const renderForm = () => (
		<form onSubmit={handleSubmit} className="space-y-6">
			<div className="grid grid-cols-2 gap-4">
				{/* Course Information */}
				<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
					<h3 className="font-medium mb-4">Course Information</h3>
					<div className="grid grid-cols-2 gap-4">
						<div className="col-span-2">
							<label className="block text-sm font-medium text-gray-700">
								Course Name
							</label>
							<input
								type="text"
								name="name"
								defaultValue={currentCourse?.name || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Course Type
							</label>
							<select
								name="type"
								defaultValue={currentCourse?.type || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							>
								<option value="">Select Type</option>
								{COURSE_TYPES.map((type) => (
									<option key={type.value} value={type.value}>
										{type.label}
									</option>
								))}
							</select>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Institution
							</label>
							<select
								name="institution_id"
								defaultValue={currentCourse?.institution_id || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							>
								<option value="">Select Institution</option>
								{institutions.map((inst) => (
									<option key={inst.id} value={inst.id}>
										{inst.name} - {inst.city}, {inst.province}
									</option>
								))}
							</select>
						</div>
						<div className="col-span-2">
							<label className="block text-sm font-medium text-gray-700">
								Description
							</label>
							<textarea
								name="description"
								defaultValue={currentCourse?.description || ""}
								rows="4"
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							/>
						</div>
					</div>
				</div>

				{/* Location Information */}
				<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
					<h3 className="font-medium mb-4">Location Information</h3>
					<div className="grid grid-cols-2 gap-4">
						<div className="col-span-2">
							<label className="block text-sm font-medium text-gray-700">
								Location Name
							</label>
							<input
								type="text"
								name="location"
								defaultValue={currentCourse?.location || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							/>
						</div>
						<div className="col-span-2">
							<label className="block text-sm font-medium text-gray-700">
								Street Address
							</label>
							<input
								type="text"
								name="street"
								defaultValue={currentCourse?.street || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								City
							</label>
							<input
								type="text"
								name="city"
								defaultValue={currentCourse?.city || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Province
							</label>
							<select
								name="province"
								defaultValue={currentCourse?.province || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							>
								<option value="">Select Province</option>
								{PROVINCES.map((province) => (
									<option key={province} value={province}>
										{province}
									</option>
								))}
							</select>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Postal Code
							</label>
							<input
								type="text"
								name="postal_code"
								defaultValue={currentCourse?.postal_code || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								pattern="[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]"
								required
							/>
						</div>
					</div>
				</div>

				{/* Schedule & Capacity */}
				<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
					<h3 className="font-medium mb-4">Schedule & Capacity</h3>
					<div className="grid grid-cols-2 gap-4">
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Start Date
							</label>
							<input
								type="date"
								name="start_date"
								defaultValue={currentCourse?.start_date || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								End Date
							</label>
							<input
								type="date"
								name="end_date"
								defaultValue={currentCourse?.end_date || ""}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Maximum Seats
							</label>
							<input
								type="number"
								name="max_seats"
								defaultValue={currentCourse?.max_seats || ""}
								min="1"
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							/>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Current Seats
							</label>
							<input
								type="number"
								name="current_seats"
								defaultValue={currentCourse?.current_seats || "0"}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								disabled
							/>
						</div>
					</div>
				</div>

				{/* Settings */}
				<div className="col-span-2 bg-gray-50 p-4 rounded-lg">
					<h3 className="font-medium mb-4">Course Settings</h3>
					<div className="grid grid-cols-2 gap-4">
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Status
							</label>
							<select
								name="active"
								defaultValue={currentCourse?.active || "1"}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							>
								<option value="1">Active</option>
								<option value="0">Inactive</option>
							</select>
						</div>
						<div>
							<label className="block text-sm font-medium text-gray-700">
								Waitlist Enabled
							</label>
							<select
								name="waitlist_enabled"
								defaultValue={currentCourse?.waitlist_enabled || "0"}
								className="mt-1 block w-full rounded border-gray-300 shadow-sm"
								required
							>
								<option value="0">No</option>
								<option value="1">Yes</option>
							</select>
						</div>
					</div>
				</div>
			</div>

			<div className="flex justify-end space-x-2 pt-4">
				<button
					type="button"
					onClick={() => setIsModalOpen(false)}
					className="px-4 py-2 text-gray-700 bg-gray-100 rounded hover:bg-gray-200"
				>
					Cancel
				</button>
				<button
					type="submit"
					className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
				>
					{isEditing ? "Update" : "Create"} Course
				</button>
			</div>
		</form>
	);

	return (
		<div>
			{/* Alert Message */}
			{alert.type && (
				<div
					className={`p-4 mb-4 rounded ${
						alert.type === "success"
							? "bg-green-50 text-green-800"
							: "bg-red-50 text-red-800"
					}`}
				>
					{alert.message}
				</div>
			)}

			{/* Header */}
			<div className="flex justify-between items-center mb-6">
				<h1 className="text-2xl font-bold">Courses</h1>
				<button
					onClick={() => {
						setIsEditing(false);
						setCurrentCourse(null);
						setIsModalOpen(true);
					}}
					className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
				>
					Add New Course
				</button>
			</div>

			{/* Table */}
			{isLoading ? (
				<div className="flex items-center justify-center p-8">
					<div className="flex items-center space-x-2">
						<i className="bi bi-arrow-clockwise animate-spin text-blue-600"></i>
						<span>Loading Courses...</span>
					</div>
				</div>
			) : (
				<div className="overflow-x-auto">
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
									Course Name
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
									Institution
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
									Location
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
									Duration
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
									Seats
								</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
									Status
								</th>
								<th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">
									Actions
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{courses.map((course) => {
								const institution = institutions.find(
									(i) => i.id === course.institution_id,
								);
								return (
									<tr key={course.id} className="hover:bg-gray-50">
										<td className="px-6 py-4">
											<div className="font-medium text-gray-900">
												{course.name}
											</div>
											<div className="text-sm text-gray-500 capitalize">
												{course.type}
											</div>
										</td>
										<td className="px-6 py-4">
											<div className="font-medium">
												{institution?.name || "Unknown Institution"}
											</div>
											<div className="text-sm text-gray-500">
												{institution?.primary_contact_name}
											</div>
										</td>
										<td className="px-6 py-4">
											<div>{course.location}</div>
											<div className="text-sm text-gray-500">
												{course.city}, {course.province}
											</div>
										</td>
										<td className="px-6 py-4">
											<div>
												{new Date(course.start_date).toLocaleDateString()}
											</div>
											<div className="text-sm text-gray-500">
												to {new Date(course.end_date).toLocaleDateString()}
											</div>
										</td>
										<td className="px-6 py-4">
											<div className="flex items-center space-x-2">
												<span className="font-medium">
													{course.current_seats}/{course.max_seats}
												</span>
												{course.waitlist_enabled &&
													course.current_seats >= course.max_seats && (
														<span className="px-2 py-1 text-xs bg-yellow-100 text-yellow-800 rounded-full">
															Waitlist
														</span>
													)}
											</div>
										</td>
										<td className="px-6 py-4">
											<div className="flex flex-col gap-1">
												<span
													className={`inline-flex px-2 py-1 rounded-full text-sm ${getStatusBadgeClass(
														course,
													)}`}
												>
													{getStatusText(course)}
												</span>
												{course.active === "1" && (
													<span className="inline-flex px-2 py-1 rounded-full text-xs bg-blue-100 text-blue-800">
														Active
													</span>
												)}
											</div>
										</td>
										<td className="px-6 py-4 text-right">
											<button
												onClick={() => {
													setIsEditing(true);
													setCurrentCourse(course);
													setIsModalOpen(true);
												}}
												className="text-blue-600 hover:text-blue-900"
											>
												Edit
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					{/* Pagination */}
					<div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6">
						<div className="flex justify-between items-center">
							<button
								onClick={() => setPage((prev) => Math.max(1, prev - 1))}
								disabled={page === 1}
								className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 disabled:opacity-50"
							>
								Previous
							</button>
							<span className="text-sm text-gray-700">
								Page {page} of {totalPages}
							</span>
							<button
								onClick={() =>
									setPage((prev) => Math.min(totalPages, prev + 1))
								}
								disabled={page === totalPages}
								className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 disabled:opacity-50"
							>
								Next
							</button>
						</div>
					</div>
				</div>
			)}

			{/* Modal */}
			<Modal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				title={isEditing ? "Edit Course" : "Add New Course"}
			>
				{renderForm()}
			</Modal>
		</div>
	);
};

export default Courses;